import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class SaltedgeHttpService {
  constructor(private httpClient: HttpClient) {}

  public login(): Observable<any> {
    return this.httpClient.get(`${base_url}api/saltedge/login`);
  }

  public refresh(loginId: string): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/login/${loginId}/refresh`
    );
  }

  public reconnect(loginId: string, body: any): Observable<any> {
    const url: string = `${base_url}api/saltedge/login/${loginId}/reconnect`;
    return this.httpClient.post(url, body);
  }

  public addBank(): Observable<any> {
    return this.httpClient.post(`${base_url}api/saltedge/connect`, {});
  }

  public searchBank(term: string): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/providers?search=${term}`
    );
  }

  public selectBank(body: any): Observable<any> {
    const url = `${base_url}api/saltedge/login/create`;
    return this.httpClient.post(url, body);
  }

  public fetchAccounts(loginId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/accounts?loginId=${loginId}`
    );
  }

  public syncAccounts(loginId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/accounts/create?loginId=${loginId}`
    );
  }

  public fetchTransactions(
    accountId: any,
    query: { [key: string]: any }
  ): Observable<any> {
    const url = parseQuery(
      `${base_url}api/saltedge/account/${accountId}/transactions`,
      query
    );
    return this.httpClient.get(url);
  }

  public syncTransactionAccount(accountId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/transactions/create?accountId=${accountId}`
    );
  }

  public import(body): Observable<any> {
    return this.httpClient.post(`${base_url}api/saltedge-import`, body);
  }

  public closeTransaction(id): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/transactions/${id}/close`
    );
  }

  public deleteTransaction(id): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/saltedge/transactions/${id}/delete`
    );
  }

  public getUnpaidInvoices(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/invoices-by-customer-name`, query);
    return this.httpClient.get(url);
  }

  public closeInvoice(data) {
    return this.httpClient.post(`${base_url}api/close-invoice-by-amount`, data);
  }

  public fetchDescriptions(): Observable<any> {
    return this.httpClient.get(`${base_url}api/saltedge/description`);
  }

  public partialPayment(id, data): Observable<any> {
    return this.httpClient.post(
      `${base_url}api/saltedge/partial-payment/${id}`,
      data
    );
  }

  public attachReceipt(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/attach-receipt`, data);
  }

  public attachTransactionReceipt(transId, receiptId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/attach-receipt/${transId}/${receiptId}?module=trans`
    );
  }

  public detachTransactionReceipt(transId, receiptId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/detach-receipt/${transId}/${receiptId}?module=trans`
    );
  }
}
