import { Component, OnInit } from '@angular/core';
import {
  Router,
  GuardsCheckStart,
  GuardsCheckEnd,
  NavigationCancel,
} from '@angular/router';
import SwiperCore, { Autoplay } from "swiper/core";
SwiperCore.use([Autoplay]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Easerp';
  loading = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckStart) {
        this.loading = true;
      }
      if (
        event instanceof GuardsCheckEnd ||
        event instanceof NavigationCancel
      ) {
        this.loading = false;
      }
    });
  }
}
