import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class StockHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}api/stocks`);
  }

  public fetchAllWithFilter(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/v2/stocks`, query);
    return this.httpClient.get(url);
  }

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}inventory/export?token=${token}`;

    window.open(url, '_blank');
  }

  public initialiseImport(data): Observable<any> {
    return this.httpClient.post(`${base_url}inventory/import-fetch`, data);
  }

  public confirmImport(data): Observable<any> {
    // return this.httpClient.post(`${base_url}api/v2/stocks/-/import`, data);
    return this.httpClient.post(`${base_url}inventory/import`, data);
  }

  public overrideStocks(data): Observable<any> {
    return this.httpClient.post(`${base_url}inventory/import-override`, data);
  }

  public fetchReports(query: { [key: string]: any }): Observable<any> {
    if (!query.product_name) {
      query.product_name = '';
    }
    const url = parseQuery(`${base_url}report/stocks`, query);
    return this.httpClient.get(url);
  }

  public fetchStockLogs (query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/v2/stocks/logs`, query);
    return this.httpClient.get(url);
  }
}
