import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputNumberComponent),
    },
  ],
})
export class InputNumberComponent implements OnInit {
  @Input() formControlName = '';
  @Input() isValid = false;
  @Input() clientError = '';
  @Input() serverError = '';
  @Input() label = '';
  @Input() parentForm: FormGroup;
  @Input() isNormal: false;
  @Input() isFormControl: false;
  @Output() handleChange = new EventEmitter();
  @Input() name: '';
  @Input() inputValue: '';
  @Input() isFloat: true;
  @Input() hideFormGroup: false;

  constructor() {}

  ngOnInit(): void {}

  onChange: any = (event) => {
    if (event.target) {
      if (!isNaN(event.target.value)) {
        event.target.value = event.target.value;
      }
    }
  };
  onTouch: any = (event) => {
    if (event.target) {
      if (!isNaN(event.target.value)) {
        event.target.value = event.target.value;
      }
    }
  };
  val = ''; // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onBlur(event) {
    if (event.target) {
      if (!isNaN(event.target.value)) {
        event.target.value = event.target.value;
      }
    }
  }

  onChangeInput(event) {
    if (!isNaN(event.target.value)) {
      this.handleChange.emit(event);
    } else {
      event.target.value = this.inputValue;
    }
  }
}
