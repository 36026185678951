import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TagInputModule } from 'ngx-chips';
import { FeahterIconModule } from '@core/feather-icon/feather-icon.module';
import { RouterModule } from '@angular/router';

import { AlertComponent } from './alert/alert.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SelectCallbackComponent } from './select-callback/select-callback.component';
import { UploadComponent } from './upload/upload.component';
import { TextareaComponent } from './textarea/textarea.component';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { EmailInvoice } from './modal/email-invoice/modal';
import { PaymentComponent } from './payment/payment';
import { InvoicePaymentComponent } from './invoice-payment/invoice-payment';
import { TableSearchComponent } from './table-search/table-search';
import { DateRangePicker } from './date-range-picker/date-range-picker';
import { AttachModal } from './attach-modal/attach-modal.component';
import { PurchaseFormComponent } from './purchase-form/purchase-form.component';
import { SwitchComponent } from './switch/switch.component';
import { NormalUpload } from './normal-upload/normal-upload.component';
import { EmployeeFormComponent } from './employee-form/employee-form.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    AlertComponent,
    InputComponent,
    SelectComponent,
    DatePickerComponent,
    SelectCallbackComponent,
    UploadComponent,
    TextareaComponent,
    InvoiceFormComponent,
    InputNumberComponent,
    EmailInvoice,
    PaymentComponent,
    TableSearchComponent,
    DateRangePicker,
    AttachModal,
    PurchaseFormComponent,
    SwitchComponent,
    NormalUpload,
    EmployeeFormComponent,
    InvoicePaymentComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SweetAlert2Module,
    NgxDropzoneModule,
    TagInputModule,
    FeahterIconModule,
    RouterModule,
    NgxMaskModule.forRoot({ validation: true }),
    MatSelectModule
  ],
  exports: [
    AlertComponent,
    InputComponent,
    SelectComponent,
    DatePickerComponent,
    SelectCallbackComponent,
    UploadComponent,
    TextareaComponent,
    InvoiceFormComponent,
    InputNumberComponent,
    EmailInvoice,
    PaymentComponent,
    TableSearchComponent,
    DateRangePicker,
    AttachModal,
    PurchaseFormComponent,
    SwitchComponent,
    NormalUpload,
    EmployeeFormComponent,
    InvoicePaymentComponent,
  ],
})
export class ComponentsModule {}
