import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class PaymentHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchPaymentTerms(): Observable<any> {
    return this.httpClient.get(`${base_url}api/paymentterms`);
  }

  public fetchPaymentMethods(): Observable<any> {
    return this.httpClient.get(`${base_url}api/payment-method-list`);
  }

  public fetchPartialPayment(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/invoice-partial-payment/${id}`);
  }

  public addPartialPayment(id, data): Observable<any> {
    return this.httpClient.post(
      `${base_url}api/invoice-partial-payment/${id}`,
      data
    );
  }

  public fetchInvoicePayments(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}report/invoice-payments`, query);
    return this.httpClient.get(url);
  }


  public addPaymentTerm(data): Observable<any> {
    return this.httpClient.post(`${base_url}admin/payment-terms`, data);
  }

  public updatePaymentTerm(id, data): Observable<any> {
    return this.httpClient.put(`${base_url}admin/payment-terms/${id}`, data);
  }

  public removePaymentTerm(id): Observable<any> {
    return this.httpClient.delete(`${base_url}admin/payment-terms/${id}`);
  }
}
