import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

const parseQuery = (url: string, query: { [key: string]: any }): string => {
  const keys = Object.keys(query);
  if (keys.length > 0) {
    keys.forEach((key: string, index: number) => {
      if (index === 0) {
        url += `?${key}=${query[key]}`;
      } else {
        url += `&${key}=${query[key]}`;
      }
    });
  }
  return url;
};

@Injectable({
  providedIn: 'root',
})
export class OrderHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/orders`, query);
    return this.httpClient.get(url);
  }

  public fetchOrderStats(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/sales-order-header`, query);
    return this.httpClient.get(url);
  }

  public addOrder(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/orders`, data);
  }

  public fetchOrderById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/orders/${id}`);
  }

  public updateOrder(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/orders/${id}`, data);
  }

  public email(body): Observable<any> {
    return this.httpClient.post(`${base_url}sales/sales-order-email`, body);
  }

  public print(id): void {
    const token: string = localStorage.getItem('easerp_token');

    const url = `${base_url}sales/orders/${id}/print?token=${token}`;

    window.open(url, '_blank');
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/orders/${id}`);
  }

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');

    const url = `${base_url}sales/sales-order/export/sales-order?token=${token}`;

    window.open(url, '_blank');
  }

  public convertToInvoice(id: number, data: any) {
    return this.httpClient.post(`${base_url}sales/convert-orders/${id}`, data);
  }

  public convertToDelivery(id: number, data: any) {
    return this.httpClient.post(`${base_url}sales/convert-delivery/${id}`, data);
  }
}
