import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthHttpService } from '@services';

import { EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private http: AuthHttpService) {}

  canActivate() {
    const token: string = localStorage.getItem('easerp_token');

    if (token) {
      return this.http.isLoggedIn().pipe(
        map((response) => {
          if (response.logout === false) {
            return true;
          }

          this.router.navigate(['/auth/login']);
          return false;
        }),
        catchError(() => {
          return EMPTY;
        })
      );
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login']);
    return false;
  }
}
