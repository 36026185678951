import {
  Component,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  Input,
  ViewChild
} from '@angular/core';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import * as dateUtil from '@utils/date';
import { NgbModal, NgbModalRef, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CustomersHttpService,
  UserHttpService,
  VendorHttpService,
  AccountsHttpService,
} from '@services';

@Component({
  selector: 'table-search',
  templateUrl: './table-search.html',
  styleUrls: ['./table-search.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableSearchComponent),
    },
  ],
})
export class TableSearchComponent implements OnInit {
  @Output() handleSearch = new EventEmitter<any>();
  @Input() isVendor = false;
  @Input() isAccounts = false;
  @Input() hideUser = false;
  @Input() hideCustomer = false;
  @Input() buttonLabel = '';
  @Input() invoicePage = false
  @Input() invoiceDatePicker = true
  @Input()invoicSearch = true
  // @Input() invoiceDatePicker = false
  @Output() buttonAction = new EventEmitter<any>();


    customerGroups = [
    { value: 'restaurant', label: 'Restaurant' },
    { value: 'own_retail', label: 'Own retail' },
    { value: 'external_retail', label: 'External retail' },
    { value: 'export', label: 'Export' },
  ];

  @ViewChild('customerSelector', { static: false }) customerSelector: MatSelect;
  @ViewChild('customergroup', { static: false }) customergroup: MatSelect;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  DateRangeModalRef: NgbModalRef;

  customerSearchId: Number = JSON.parse(localStorage.getItem('invoiceSearchTerm')) || -1;

  constructor(
    private userHttp: UserHttpService,
    private customerHttp: CustomersHttpService,
    private vendorHttp: VendorHttpService,
    private accountsHttp: AccountsHttpService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.fetchUsers();
    if (this.isVendor) {
      this.fetchVendors();
    } else if (this.isAccounts) {
      this.fetchAccounts();
    } else {
      this.fetchCustomers();
      if (this.customerSearchId !== -1) {
        this.onSubmit('customer_name' ,this.customerSearchId);
      }
    }
  }

  customers = [];
  users = [];
  vendors = [];
  accounts = [];
  selectedCustomers = [];

  fetchUsers() {
    this.userHttp
      .fetchAll()
      .pipe(
        map((response: any) => {
          this.users = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  fetchAccounts() {
    this.accountsHttp
      .fetchAll()
      .pipe(
        map((response: any) => {
          const accounts = [];
          response.forEach((res) => {
            res.children.forEach((child) => {
              accounts.push({ ...child, parentLabel: res.account_name });
            });
          });
          this.accounts = accounts;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  fetchCustomers() {
    this.customerHttp
      .fetchAll({})
      .pipe(
        map((response: any) => {
          this.customers = response;
          this.selectedCustomers = this.customers;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  fetchVendors() {
    this.vendorHttp
      .fetchAll()
      .pipe(
        map((response: any) => {
          this.vendors = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  fetchLocalStorageCustomerId() {
    const customerId = JSON.parse(localStorage.getItem('invoiceSearchTerm'));
    if (customerId) {
      return customerId;
    } else {
      return EMPTY;
    }
  }

  onClickTableSearch() {
    const inputNode = document.getElementById('invoice-table-search-input');
    inputNode.focus();
  }

  searchCustomer(e: any) {
    let filter = e.target.value.toLowerCase();
    this.selectedCustomers = this.customers.filter(c => c.full_name.toLowerCase().includes(filter));
  }

  changeUserFilter(e: any) {
    let value = '';
    if (e) {
      value = e.id;
    }

    this.onSubmit('user_id', value);
  }

  changeCustomerFilter(value: string) {

    // Remove focus from the dropdown after selection:
    this.customerSelector.close();

    if (value) {
      localStorage.setItem('invoiceSearchTerm', JSON.stringify(value));
    } else {
      localStorage.removeItem('invoiceSearchTerm');      
    }
      this.onSubmit('customer_name', value);
  
  
  }


  changeGroupsFilter(value:string){
    this.customergroup.close();
    if (value) {
      localStorage.setItem('invoiceSearchGroupTerm', JSON.stringify(value));
    } else {
      localStorage.removeItem('invoiceSearchGroupTerm');      
    }
      this.onSubmit('customer_group', value);
  
  }

  changeVendorFilter(e: any) {
    let value = '';
    if (e) {
      value = e.vendor_id;
    }

    this.onSubmit('last_name', value);
  }

  changeAccountFilter(e: any) {
    let value = '';
    if (e) {
      value = e.id;
    }

    this.onSubmit('account_id', value);
  }

  clearCustomerSelection() {
    localStorage.removeItem('invoiceSearchTerm');    
    this.changeCustomerFilter('');  
  }

  clearCustomerGroup(){
    localStorage.removeItem('invoiceSearchGroupTerm');     
     this.customergroup.value = null;
    this.changeGroupsFilter('');
  }

  onSubmit(key, value) {
    this.handleSearch.emit({ key, value });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      const start = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
      const end = `${date.year}-${date.month}-${date.day}`;
      this.searchDate(start, end);
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  openDateRangeModal(content) {
    this.DateRangeModalRef = this.modal.open(content, {
      size: 'l',
      scrollable: true,
    });
  }

  closeDateRangeModal() {
    this.DateRangeModalRef.close();
  }

  searchDate(fromDate, toDate) {
    this.closeDateRangeModal();
    this.handleSearch.emit({
      key: 'date',
      value: {
        start: fromDate,
        end: toDate,
      },
    });
  }

  selectToday() {
    const today = dateUtil.getToday();
    this.searchDate(today, today);
  }

  selectThisWeek() {
    const start = dateUtil.getWeekStart();
    const end = dateUtil.getWeekEnd();
    this.searchDate(start, end);
  }

  selectThisMonth() {
    const start = dateUtil.getMonthStart();
    const end = dateUtil.getMonthEnd();
    this.searchDate(start, end);
  }

  selectThisYear() {
    const start = dateUtil.getYearStart();
    const end = dateUtil.getYearEnd();
    this.searchDate(start, end);
  }

  selectLastWeek() {
    const start = dateUtil.getLastWeekStart();
    const end = dateUtil.getLastWeekEnd();
    this.searchDate(start, end);
  }

  selectLastMonth() {
    const start = dateUtil.getLastMonthStart();
    const end = dateUtil.getLastMonthEnd();
    this.searchDate(start, end);
  }

  selectLastYear() {
    const start = dateUtil.getLastYearStart();
    const end = dateUtil.getLastYearEnd();
    this.searchDate(start, end);
  }

  clear() {
    this.searchDate('', '');
  }

  buttonClick() {
    this.buttonAction.emit();
  }
}
