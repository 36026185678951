import * as moment from 'moment';

const companyDateFormat = JSON.parse(localStorage.getItem('company_date_format')) || '';

export const getFormattedDate = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (companyDateFormat === 'm/d/Y') return [month, day, year].join('/');
  if (companyDateFormat === 'd/m/Y') return [day, month, year].join('/');
  if (companyDateFormat === 'Y/m/d') return [year, month, day].join('/');
  if (companyDateFormat === 'd.m.Y') return [day, month, year].join('.');
  if (companyDateFormat === 'Y-m-d') return [year, month, day].join('-');
  return [year, month, day].join('-');
};

export const getToday = () => {
  if (companyDateFormat === 'm/d/Y') return moment().format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().format('YYYY-MM-DD');
  return moment().format('YYYY-MM-DD');
};

export const getYesterday = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'days').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'days').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'days').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'days').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'days').format('YYYY-MM-DD');
  return moment().subtract(1, 'days').format('YYYY-MM-DD');
};

export const getMonthStart = () => {
  if (companyDateFormat === 'm/d/Y') return moment().startOf('month').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().startOf('month').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().startOf('month').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().startOf('month').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().startOf('month').format('YYYY-MM-DD');
  return moment().startOf('month').format('YYYY-MM-DD');
};

export const getMonthEnd = () => {
  if (companyDateFormat === 'm/d/Y') return moment().endOf('month').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().endOf('month').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().endOf('month').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().endOf('month').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().endOf('month').format('YYYY-MM-DD');
  return moment().endOf('month').format('YYYY-MM-DD');
};

export const getWeekStart = () => {
  if (companyDateFormat === 'm/d/Y') return moment().startOf('week').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().startOf('week').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().startOf('week').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().startOf('week').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().startOf('week').format('YYYY-MM-DD');
  return moment().startOf('week').format('YYYY-MM-DD');
};

export const getWeekEnd = () => {
  if (companyDateFormat === 'm/d/Y') return moment().endOf('week').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().endOf('week').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().endOf('week').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().endOf('week').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().endOf('week').format('YYYY-MM-DD');
  return moment().endOf('week').format('YYYY-MM-DD');
};

export const getYearStart = () => {
  if (companyDateFormat === 'm/d/Y') return moment().startOf('year').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().startOf('year').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().startOf('year').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().startOf('year').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().startOf('year').format('YYYY-MM-DD');
  return moment().startOf('year').format('YYYY-MM-DD');
};

export const getYearEnd = () => {
  if (companyDateFormat === 'm/d/Y') return moment().endOf('year').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().endOf('year').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().endOf('year').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().endOf('year').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().endOf('year').format('YYYY-MM-DD');
  return moment().endOf('year').format('YYYY-MM-DD');
};

export const getLastMonthStart = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'months').startOf('month').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
};

export const getLastMonthEnd = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
};

export const getLastWeekStart = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'weeks').startOf('week').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'weeks').startOf('week').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'weeks').startOf('week').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'weeks').startOf('week').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
  return moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
};

export const getLastWeekEnd = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'weeks').endOf('week').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'weeks').endOf('week').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'weeks').endOf('week').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'weeks').endOf('week').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
  return moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
};

export const getLastYearStart = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'years').startOf('year').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'years').startOf('year').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'years').startOf('year').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'years').startOf('year').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
  return moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
};

export const getLastYearEnd = () => {
  if (companyDateFormat === 'm/d/Y') return moment().subtract(1, 'years').endOf('year').format('MM/DD/YYYY');
  if (companyDateFormat === 'd/m/Y') return moment().subtract(1, 'years').endOf('year').format('DD/MM/YYYY');
  if (companyDateFormat === 'Y/m/d') return moment().subtract(1, 'years').endOf('year').format('YYYY/MM/DD');
  if (companyDateFormat === 'd.m.Y') return moment().subtract(1, 'years').endOf('year').format('DD.MM.YYYY');
  if (companyDateFormat === 'Y-m-d') return moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
  return moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
};
