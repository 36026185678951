import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  AfterContentChecked,
  ChangeDetectorRef
} from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  VendorHttpService,
  PaymentHttpService,
  ProductHttpService,
  TaxHttpService,
  UnitHttpService,
  LocationHttpService,
  AppService,
  CompanyHttpService,
  ReceiveNotesHttpService
} from '@services';
import Swal from 'sweetalert2';

@Component({
  selector: 'purchase-form',
  templateUrl: './purchase-form.component.html',
  styleUrls: ['./purchase-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PurchaseFormComponent),
    },
  ],
})
export class PurchaseFormComponent implements OnInit, AfterContentChecked {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Input() isSubmitting = false;
  @Input() id = '';
  @Input() title = '';
  @Input() source = false;
  @Input() isModal = false;
  @Input() includeList = [];
  @Input()
  set parentSelectedProducts(value) {
    this.selectedProducts = value;
  }
  @Input()
  set parentSelectedProductsId(value) {
    this.selectedProductsId = value;
  }
  @Input() parentForm: FormGroup;
  @Output() handleSubmit = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter();
  @Input() skipProductCheck = false;

  vendors = [];
  address = [];
  paymentTerms = [];
  products = [];
  taxRates = [];
  productFamilies = [];
  units = [];
  locations = [];
  visibleProducts = [];

  customerAddressMap = {};
  unitMap = {};
  productFamiliesMap = {};
  taxMap = {};

  statusOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  salesOrderStatus = [
    { label: 'Open', value: 0 },
    { label: 'Close', value: 1 },
  ];
  orderStatus = [
    { label: 'Open', value: '0' },
    { label: 'Close', value: '1' },
  ];
  receiveNoteStatusOptions = [
    { label: 'Not Billed / Open', value: 0 },
    { label: 'Billed / Closed', value: 1 },
  ];
  receiveNoteIsNewArrivalOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
  ];
  SelectProductModalRef: NgbModalRef;
  draftProductsId = []; // Only contains id which is yet to be saved
  selectedProductsId = []; // Only contains id which is saved
  draftProducts = []; // Contains product detail which is yet to be saved
  selectedProducts = []; // Contains product detail which is saved

  subscription: Subscription;

  includeTax = true;

  isPageLoading: boolean = false;

  constructor(
    private vendorHttp: VendorHttpService,
    private paymentHttp: PaymentHttpService,
    private productHttp: ProductHttpService,
    private taxHttp: TaxHttpService,
    private unitHttp: UnitHttpService,
    private locationHttp: LocationHttpService,
    private modal: NgbModal,
    private appService: AppService,
    private loadingBar: LoadingBarService,
    private companyHttp: CompanyHttpService,
    private receiveNotesHttp: ReceiveNotesHttpService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.onLoad();

    this.subscription = this.appService.formChangeEvent().subscribe((res) => {
      if (res && res.recalculate) {
        setTimeout(() => {
          this.calculateValues();
        }, 200);
      }
    });
  }

  onLoad() {
    this.loadingBar.start();
    this.isPageLoading = true;

    combineLatest([
      this.vendorHttp.fetchAll(),
      this.paymentHttp.fetchPaymentTerms(),
      this.productHttp.fetchAll(),
      this.locationHttp.fetchAll(),
      this.taxHttp.fetchAll(),
      this.unitHttp.fetchAll(),
      this.productHttp.fetchProductFamilies(),
      this.companyHttp.fetch(),
    ]).subscribe(
      ([
        vendors,
        paymentTerms,
        products,
        locations,
        taxes,
        units,
        productFamilies,
        company,
      ]) => {
        this.vendors = vendors;

        this.paymentTerms = paymentTerms;
        this.parentForm.controls.payment_term_id.setValue(paymentTerms[0].id);

        this.products = products;
        this.visibleProducts = products;

        this.locations = locations;

        this.taxRates = taxes;
        taxes.forEach((tax) => {
          this.taxMap[tax.id] = tax.rate;
        });

        this.units = units;
        units.forEach((single) => {
          this.unitMap[single.unit_id] = single.unit_code;
        });

        this.productFamilies = productFamilies;
        productFamilies.forEach((single) => {
          this.productFamiliesMap[single.product_family_id] =
            single.product_family_desc;
        });

        this.includeTax = company.tax_include;

        this.calculateValues();
        this.loadingBar.stop();
        this.isPageLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openSelectProductModal(content) {
    this.SelectProductModalRef = this.modal.open(content, {
      size: 'xl',
      scrollable: true,
    });
  }

  closeSelectProductModal() {
    this.draftProducts = [...this.selectedProducts];
    this.draftProductsId = [...this.selectedProductsId];
    this.SelectProductModalRef.close();
  }

  selectProductRow(product) {
    this.draftProductsId.push(product.product_id);
    this.draftProducts.push({
      apply_tax: this.taxRates[0].id,
      apply_tax_value: this.taxRates[0].rate,
      custom_price: false,
      disc_percent: '0.00',
      inventory_location_id: this.locations[0].location_id,
      inventory_location_desc: this.locations[0].location_desc,
      inventory_tracking: true,
      product_id: product.product_id,
      product_name: product.product_name,
      product_ordered_desc: '',
      qty: product.receive_qty || 1,
      stock_amount: '-1.00',
      straight_line_sum: product.sales_price,
      sub_total: product.sales_price,
      unit_amount: product.sales_price,
      hide: 0,
    });
  }

  removeProductRow(index: number) {
    Swal.fire({
      title: 'Remove product row',
      text: 'Are you sure you want to remove this product row?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#727CF5',
      showDenyButton: true,
      denyButtonText: 'Deny',
      denyButtonColor: '#ff3366'
    }).then(result => {
      if (result.isConfirmed) {
        this.selectedProducts.splice(index, 1);
        this.selectedProductsId.splice(index, 1);
        this.calculateValues();
      }
    });
  }

  saveSelectProductModal() {
    this.selectedProducts = [...this.selectedProducts, ...this.draftProducts];
    this.selectedProductsId = [
      ...this.selectedProductsId,
      ...this.draftProductsId,
    ];
    this.draftProducts = [];
    this.draftProductsId = [];

    this.calculateValues();
    if (this.SelectProductModalRef) {
      this.SelectProductModalRef.close();
    }
  }

  calculateValues() {
    let subtotal = 0;
    let salestax = 0;
    let selectedProducts = [...this.selectedProducts];

    selectedProducts.forEach((product, i) => {
      let productSubTotal = 0;
      const taxRate = Number(this.taxMap[product.apply_tax] || '0');
      const discountRate = Number(product.disc_percent || '0');
      const unitAmount = Number(product.unit_amount || '0');
      const qty = Number(product.qty || '0');

      productSubTotal =
        qty * unitAmount - (discountRate / 100) * qty * unitAmount;
      subtotal += productSubTotal;
      if (this.includeTax) {
        salestax += (taxRate / 100) * productSubTotal;
      }

      this.selectedProducts[i].sub_total = productSubTotal.toFixed(2);
      this.selectedProducts[i].apply_tax_value = this.taxMap[product.apply_tax];
    });

    const discountRate = Number(
      this.parentForm.controls.discount_rate.value || '0'
    );

    const discountAmount = ((subtotal * discountRate) / 100).toFixed(2);
    const totalAmountBeforeTax = subtotal - Number(discountAmount);
    const totalAmountAfterTax = (totalAmountBeforeTax + salestax).toFixed(2);

    this.parentForm.controls.discount_amount.setValue(discountAmount);

    if (this.includeList.includes('total_amount')) {
      this.parentForm.controls.total_amount.setValue(totalAmountAfterTax);
    } else {
      this.parentForm.controls.grand_total.setValue(totalAmountAfterTax);
    }

    if (this.includeList.includes('sub_total')) {
      this.parentForm.controls.sub_total.setValue(subtotal.toFixed(2));
    } else {
      this.parentForm.controls.subtotal.setValue(subtotal.toFixed(2));
    }

    if (this.includeList.includes('tax_amount')) {
      this.parentForm.controls.tax_amount.setValue(salestax.toFixed(2));
    } else {
      this.parentForm.controls.sales_tax.setValue(salestax.toFixed(2));
    }
  }

  onChangeTableData(e, i) {
    e.preventDefault();
    const { name, value } = e.target;
    this.selectedProducts[i][name] = value;
    this.calculateValues();
  }

  onSubmit() {
    if (this.parentForm?.valid) {
      const data = {
        ...this.parentForm.value,
        choices: this.selectedProducts,
        documents: [],
        tax_amount: this.parentForm.value.tax_amount,
      };
      this.handleSubmit.emit(data);
    }
  }

  goBack() {
    this.handleCancel.emit();
  }

  onChangeSearch(e) {
    const products = [];
    this.products.forEach((product) => {
      if (
        product.product_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        products.push(product);
      }
    });

    this.visibleProducts = products;
  }

  selectProduct(event) {
    if (event) {
      this.ngSelectComponent.handleClearClick();
      this.selectProductRow(event);
      this.saveSelectProductModal();
    }
  }

  onImportItemsClick() {
    const uploadReceiveNoteInput = document.getElementById('upload-receive-note');
    uploadReceiveNoteInput.click();
  }

  importReceiveNoteProducts(e: any) {
    const fileUploaded = e.target.files[0];

    const formData = new FormData();
    formData.append('file', fileUploaded);
    this.receiveNotesHttp
        .importProductsFromReceiveNote(formData)
        .pipe(
          map((response: any) => {
            response.forEach((x: any) => {
              this.selectProductRow(x);
              this.saveSelectProductModal();
            });
          }
        )
      )
      .subscribe();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
