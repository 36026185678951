import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  Injectable,
} from '@angular/core';

import { EMPTY, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';

import {
  FormGroup,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';

import {
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

import { PaymentHttpService, AppService, InvoicesHttpService } from '@services';

import { getFormattedDate } from '@utils/date';

@Injectable()
export class CustomDateAdapter {
  fromModel(value: string): NgbDateStruct {
    if (!value) return null;
    let parts = value.split('-');
    return { year: +parts[0], month: +parts[1], day: +parts[2] };
  }

  toModel(date: NgbDateStruct): string {
    // from internal model -> your mode
    return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : null;
  }
}

@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value) return null;
    let parts = value.split('-');
    return {
      year: +parts[0],
      month: +parts[1],
      day: +parts[2],
    } as NgbDateStruct;
  }
  format(date: NgbDateStruct): string {
    return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : null;
  }
}

@Component({
  selector: 'invoice-payment',
  templateUrl: './invoice-payment.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InvoicePaymentComponent),
    },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class InvoicePaymentComponent implements OnInit {
  @Input() isModal = false;
  @Input() transaction = {};
  @Output() handleSubmit = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter();

  paymentMethods = [];
  partialPayments = [];
  data = {
    id: '',
    total: '',
    due: '',
    dueDate: '',
    invoiceDate: '',
    paid: '',
  };

  invoice = {};

  PaymentForm: FormGroup;
  subscription: Subscription;

  constructor(
    private appService: AppService,
    private http: PaymentHttpService,
    private invoiceHttp: InvoicesHttpService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.subscription = this.appService.formChangeEvent().subscribe((res) => {
      if (res && res.id) {
        this.data.id = res.id;
        this.initData();
      }
    });

    this.fetchPaymentMethods();
    this.buildForm();
  }

  initData() {
    this.fetchPartialPayment();
  }

  fetchPaymentMethods() {
    this.http
      .fetchPaymentMethods()
      .pipe(
        map((response: any) => {
          this.paymentMethods = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  get payments(): FormArray {
    return this.PaymentForm.get('payments') as FormArray;
  }

  fetchPartialPayment() {
    this.invoiceHttp
      .fetchById(this.data.id)
      .pipe(
        map((response: any) => {
          this.data.invoiceDate = response.create_date;
          this.data.paid = response.amount;
          this.data.total = response.total_amount;
          this.data.due = (
            response.total_amount - this['transaction']['amount']
          ).toFixed(2);
          this.PaymentForm = this.fb.group({
            payments: this.fb.array([]),
          });

          this.payments.push(
            this.fb.group({
              payment_date: this.transaction['made_on'],
              paid_amount: this.transaction['amount'],
              bank_reference: this.transaction['bank_reference_no'],
              bank_account: this.transaction['account_id'],
              payment_method: '',
            })
          );
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  buildForm() {
    this.PaymentForm = this.fb.group({
      payments: this.fb.array([
        this.fb.group({
          bank_account: [''],
          bank_reference: [''],
          days_late: [0],
          payment_date: [getFormattedDate()],
          paid_amount: [this.data.due, Validators.required],
          payment_method: ['', Validators.required],
        }),
      ]),
    });
  }

  onBlur(event) {
    if (event.target) {
      if (!isNaN(event.target.value)) {
        event.target.value = event.target.value;
      }
    }
  }

  onChangeAmount() {
    const values = this.PaymentForm.value;
    let due: number = Number(this.data.total);
    values.payments.forEach((payment) => {
      due -= Number(payment.paid_amount);
    });
    this.data.due = due.toFixed(2);
  }

  removePaymentRow(i) {
    this.payments.removeAt(i);
    this.onChangeAmount();
  }

  onSubmit() {
    if (this.isModal) {
      if (this.PaymentForm.valid) {
        this.handleSubmit.emit({
          id: this.data.id,
          body: this.PaymentForm.value.payments[0],
          transaction: this.transaction['transaction_id'],
        });
      }
    }
  }

  onCancel() {
    if (this.isModal) {
      this.handleCancel.emit();
    }
  }
}
