import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class AccountsHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}api/account-list`);
  }

  public fetchBalanceSheets(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/accounts/balance-sheet`, query);
    return this.httpClient.get(url);
  }

  public fetchAllWithDetails(): Observable<any> {
    return this.httpClient.get(`${base_url}api/accounts`);
  }

  public addSubAccount(id, data): Observable<any> {
    return this.httpClient.post(`${base_url}api/accounts/child/${id}`, data);
  }

  public updateSubAccount(id, data): Observable<any> {
    return this.httpClient.put(`${base_url}api/accounts/child/${id}`, data);
  }

  public create(data) {
    return this.httpClient.post(`${base_url}api/accounts`, data);
  }

  public fetchProfitAndLoss(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/accounts/profit-loss`, query);
    return this.httpClient.get(url);
  }
}
