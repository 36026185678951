import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class ReceiptsHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/receipts`, query);
    return this.httpClient.get(url);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/receipts/${id}`);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/receipts/${id}`);
  }

  public add(formData): Observable<any> {
    return this.httpClient.post(`${base_url}api/receipts`, formData);
  }

  public emailApixReports(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/apix-invoices-export`, query);
    return this.httpClient.get(url);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/receipts/${id}`, data);
  }

  public reprocess(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/receipts/${id}/edit`);
  }
}
