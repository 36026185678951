import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';
import { productParseQuery } from '../app/utils/productQuery';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class ProductHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}api/products-list`);
  }

  // This API includes obselete products as well:
  public fetchAll_v2(): Observable<any> {
    return this.httpClient.get(`${base_url}api/v2/products-list`);
  }

  public fetchAllWithFilter(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/products`, query);
    return this.httpClient.get(url);
  }

  public fetchProductByBarcode(query: { [key: string]: any }, query2: { [key: string]: any }): Observable<any>{
    const url = productParseQuery(`${base_url}api/customer/v2/products`, query, query2);
    return this.httpClient.get(url);
  }

  public fetchProductByProductCode(query: { [key: string]: any }, query2: { [key: string]: any }): Observable<any>{
    const url = productParseQuery(`${base_url}api/customer/v2/products`, query, query2);
    return this.httpClient.get(url);
  }

  public fetchProductFamilies(): Observable<any> {
    return this.httpClient.get(`${base_url}api/products-families`);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/products/${id}`);
  }

  public addProduct(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/products`, data);
  }

  public fetchProductById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/products/${id}`);
  }

  public updateProduct(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/products/${id}`, data);
  }

  public addProductFamily(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/products-families`, data);
  }

  public updateProductFamily(id, data): Observable<any> {
    return this.httpClient.patch(
      `${base_url}api/products-families/${id}`,
      data
    );
  }

  public deleteProductFamily(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/products-families/${id}`);
  }

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}sales/products/export/product?token=${token}`;

    window.open(url, '_blank');
  }

  public initialiseImport(data): Observable<any> {
    return this.httpClient.post(
      `${base_url}sales/products/import/product`,
      data
    );
  }

  public confirmImport(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/v2/products/-/import`, data);
  }

  public fetchReports(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}report/product-reports`, query);
    return this.httpClient.get(url);
  }

  public getProductPrice(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/price-by-product`, query);
    return this.httpClient.get(url);
  }

  public fetchMarginReports(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}report/margin-reports`, query);
    return this.httpClient.get(url);
  }
}
