import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  dashboardIcons: any[] = [
    {
      name: 'SALES',
      icon: 'shopping_cart',
      link: '/sales-order',
    },
    {
      name: 'INVENTORY',
      icon: 'business',
      link: '/inventories/stocks',
    },
    {
      name: 'PURCHASING',
      icon: 'shopping_basket',
      link: '/purchase/einvoice',
    },
    {
      name: 'REPORT',
      icon: 'menu_book',
      link: 'reports/apix-export',
    },
    {
      name: 'ACCOUNTING',
      icon: 'show_chart',
      link: '/accounting/saltedge',
    },
    {
      name: 'MARKETING',
      icon: 'chat_bubble_outline',
      link: '/marketing/email',
    },
    {},
    {
      name: 'HRM',
      icon: 'business_center',
      link: '/hrm/employee-data',
    },
    {}
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
