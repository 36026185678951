import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';


const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class DeliveryNotesHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/delivery-notes`, query);
    return this.httpClient.get(url);
  }

  public fetchStats(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/delivery-note-header`, query);
    return this.httpClient.get(url);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/delivery-notes`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/delivery-notes/${id}`, data);
  }

  public fetchById(id): Observable<unknown> {
    return this.httpClient.get(`${base_url}api/delivery-notes/${id}`);
  }

  public fetchByGroup(group): Observable<unknown> {
    return this.httpClient.get(`${base_url}api/delivery-notes?customer_group=/${group}`);
  }

  public remove(id): Observable<unknown> {
    return this.httpClient.delete(`${base_url}api/delivery-notes/${id}`);
  }

  public email(body): Observable<any> {
    return this.httpClient.post(`${base_url}sales/delviery-note-email`, body);
  }

  public print(id): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}sales/delivery-notes/${id}/print?token=${token}`;
    window.open(url, '_blank');
  }

  public getPickingList(id: Number): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}sales/delivery-notes/${id}/pickinglist-print?token=${token}`;
    window.open(url, '_blank');
  };

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}sales/delivery-notes/export/delivery-notes?token=${token}`;
    window.open(url, '_blank');
  }

  public mergeInvoices(customer_id, delivery_note_id) {
    let params = new HttpParams();
    for (let i = 0; i <= customer_id.length - 1; i++) {
      params = params.append('delivery_note_id[]', delivery_note_id[i]);
    }
    for (let i = 0; i <= customer_id.length - 1; i++) {
      params = params.append('customer_id[]', customer_id[i]);
    }

    return this.httpClient.post(
      `${base_url}api/multiple-delivery-note-to-invoice`,
      params
    );
  }

  public fetchDeliveryNotesCount(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}report/delivery-note/-/counts`, query);
    return this.httpClient.get(url);
  }
}
