import { Observable } from 'rxjs';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class OthersHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchHolidays(): Observable<any> {
    return this.httpClient.get(`${base_url}api/holidays`);
  }

  public fetchHolidaysFromCalendarific(): Observable<any> {
    const params = new HttpParams()
      .set('api_key', environment.CALENDARIFIC_API_KEY)
      .set('country', 'FI')
      .set('year', moment().year().toString());

    return this.httpClient.get(`https://calendarific.com/api/v2/holidays`, {
      params,
    });
  }
}
