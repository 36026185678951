import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class UnitHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}api/units`);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/units`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.put(`${base_url}api/units/${id}`, data);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/units/${id}`);
  }
}
