import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class ProfileHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchNotes(): Observable<any> {
    return this.httpClient.get(`${base_url}api/notes`);
  }

  public addNote(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/notes`, data);
  }

  public deleteNote(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/notes/${id}`);
  }

  public fetchUserDetail(): Observable<any> {
    return this.httpClient.get(`${base_url}api/user`);
  }

  public updateUserDetail(data): Observable<any> {
    return this.httpClient.post(`${base_url}update-profile`, data);
  }

  public changePassword(data): Observable<any> {
    return this.httpClient.post(`${base_url}change-password`, data);
  }
}
