import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class JournalHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/journals`, query);
    return this.httpClient.get(url);
  }

  public fetchTypes(): Observable<any> {
    return this.httpClient.get(`${base_url}api/journal_types`);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/journals`, data);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/journals/${id}`);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/journals/${id}`, data);
  }
}
