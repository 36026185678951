import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements OnInit {
  @Input() type = '';
  @Input() formControlName = '';
  @Input() isValid = false;
  @Input() clientError = '';
  @Input() serverError = '';
  @Input() label = '';
  @Input() parentForm: FormGroup;
  @Input() readonly: boolean = false;
  @Input() isNormal: false;
  @Output() handleChange = new EventEmitter();
  @Input() hideFormGroup: false;
  @Input() checkboxName = '';
  @Input() inputName = '';
  @Input() checkboxValue = '';
  @Input() inputValue = '';
  @Input() defaultValue= '';
  @Input() disabled=false;
  @Input() pageType ='';
  isPasswordVisible: boolean = false; 

  constructor() {}

  ngOnInit(): void {}

  onChange: any = () => {};
  onTouch: any = () => {};
  val = ''; // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  changeValue(event) {
    this.handleChange.emit(event);
  }

  changeInput(event) {
    this.handleChange.emit(event);
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
