import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';

import { ReceiptsHttpService } from '@services';
import { successAlert } from '@utils/alert';

@Component({
  selector: 'attach-modal',
  templateUrl: './attach-modal.component.html',
})
export class AttachModal implements OnInit {
  @Output() handleSubmit = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter();

  filter = {
    page: 1,
  };
  receipts = [];
  stats = {
    from: 0,
    to: 0,
    total: 0,
    per_page: 30,
    last_page: 1,
    current_page: 1,
    prev_page_url: false,
    next_page_url: false,
  };

  constructor(private http: ReceiptsHttpService) {}

  ngOnInit() {
    this.fetchReceipts();
  }

  onCancel() {
    this.handleCancel.emit();
  }

  onSubmit(fileId) {
    this.handleSubmit.emit(fileId);
  }

  fetchReceipts() {
    this.http
      .fetchAll(this.filter)
      .pipe(
        map((response: any) => {
          this.receipts = response.data;
          this.stats = {
            from: response.from,
            to: response.to,
            total: response.total,
            per_page: response.per_page,
            last_page: response.last_page,
            current_page: response.current_page,
            prev_page_url: response.prev_page_url,
            next_page_url: response.next_page_url,
          };
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  changePage(page) {
    this.filter.page = page;
  }

  addReceipt(files) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('documents[]', files[i]);
    }
    this.http
      .add(formData)
      .pipe(
        map((response: any) => {
          const fileId = response.files[0].id;
          this.onSubmit(fileId);
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  deleteReceipt(id) {
    this.http
      .remove(id)
      .pipe(
        map((response: any) => {
          successAlert('Successfully deleted!!', response.message);
          this.fetchReceipts();
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }
}
