import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class AdminHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}api/account-list`);
  }

  public fetchActiveModules(): Observable<any> {
    return this.httpClient.get(`${base_url}admin/current-modules`);
  }

  public fetchPendingModules(): Observable<any> {
    return this.httpClient.get(`${base_url}admin/modules`);
  }

  public addModule(id): Observable<any> {
    return this.httpClient.get(`${base_url}admin/add-module?module_id=${id}`);
  }

  public removeModule(id): Observable<any> {
    return this.httpClient.get(`${base_url}admin/remove-module/${id}`);
  }

  public fetchCurrencies(): Observable<any> {
    return this.httpClient.get(`${base_url}admin/currency-list`);
  }

  public fetchAutoNumberings(): Observable<any> {
    return this.httpClient.get(`${base_url}admin/auto-numbering`);
  }

  public addCurrency(data): Observable<any> {
    return this.httpClient.post(`${base_url}admin/currency`, data);
  }

  public updateCurrency(id, data): Observable<any> {
    return this.httpClient.put(`${base_url}admin/currency/${id}`, data);
  }

  public removeCurrency(id): Observable<any> {
    return this.httpClient.delete(`${base_url}admin/currency/${id}`);
  }

  public addAutoNumber(data): Observable<any> {
    return this.httpClient.post(`${base_url}admin/auto-numbering`, data);
  }

  public updateAutoNumber(id, data): Observable<any> {
    return this.httpClient.put(`${base_url}admin/auto-numbering/${id}`, data);
  }

  public removeAutoNumber(id): Observable<any> {
    return this.httpClient.delete(`${base_url}admin/auto-numbering/${id}`);
  }

  public updateCompanySetting(data): Observable<any> {
    return this.httpClient.post(`${base_url}admin/company-settings`, data);
  }
}
