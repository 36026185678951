import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class SalesReceiptsHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/sales-receipts`, query);
    return this.httpClient.get(url);
  }

  public fetchStats(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/sales-receipt-header`, query);
    return this.httpClient.get(url);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/sales-receipts/${id}`);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/sales-receipts`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/sales-receipts/${id}`, data);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/sales-receipts/${id}`);
  }

  public fetchCategory(): Observable<any> {
    return this.httpClient.get(`${base_url}api/sales-receipt-category`);
  }

  public fetchAllReceipts(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/receipts`, query);
    return this.httpClient.get(url);
  }

  public attach(receiptId, fileId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/attach-receipt/${receiptId}/${fileId}?module=sales-receipt`
    );
  }

  public closeBulk(data): Observable<any> {
    return this.httpClient.post(
      `${base_url}api/sales-receipts-close-bulk`,
      data
    );
  }
}
