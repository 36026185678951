export const parseQuery = (
  url: string,
  query: { [key: string]: any },
  repeatKey: string = '',
  repeatLabel: string = ''
): string => {

  const keys = Object.keys(query);

  if (keys.length > 0) {
    let isDirty = false;

    keys.forEach((key: string, index: number) => {
      if (key === repeatKey) {
        return;
      }

      if (index === 0 || !isDirty) {
        isDirty = true;
        url += `?${key}=${query[key]}`;
      } else {
        url += `&${key}=${query[key]}`;
      }
    });

    if (repeatKey) {
      if (query[repeatKey] && query[repeatKey].length > 0) {
        query[repeatKey].forEach((value: any) => {
          if (!isDirty) {
            isDirty = true;
            url += `?${repeatLabel}=${value}`;
          } else {
            url += `&${repeatLabel}=${value}`;
          }
        });
      }
    }
  }

  return url;
};
