import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() saveOnSelect = false;
  @Output() addFile = new EventEmitter<any>();

  files: File[] = [];
  serverFiles: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.saveOnSelect) {
      this.addFile.emit(this.files);
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
