import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked
} from '@angular/core';
import { environment } from '@env/environment';
import { EMPTY, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CustomersHttpService,
  PaymentHttpService,
  ProductHttpService,
  TaxHttpService,
  UnitHttpService,
  LocationHttpService,
  AppService,
  CompanyHttpService,
  EmployeeHttpService,
  ReceiveNotesHttpService,
} from '@services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { formatNumber } from '@utils/number';
import { errorAlert, successAlert, warningAlert } from '@utils/alert';
import Swal from 'sweetalert2';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InvoiceFormComponent),
    },
  ],
})
export class InvoiceFormComponent implements OnInit, AfterContentChecked {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @ViewChild('searchMenu') ngSelectModalComponent: NgSelectComponent;
  @ViewChild('productSelector', { static: false }) productSelector: MatSelect;

  @Input() isSubmitting = false;
  @Input() id = '';
  @Input() code = '';
  @Input() title = '';
  @Input() source = '';
  @Input() isModal = false;
  @Input() excludeList = [];
  @Input() includeList = [];
  @Input() deliveryNotes = [];
  @Input() salesOrders = [];
  @Input() invoices = [];
  @Input() hidePaymentTerm = false;
  @Input()
  set parentSelectedProducts(value) {
    this.selectedProducts = value;
    this.calculateValues();
  }
  @Input()
  set parentSelectedProductsId(value) {
    this.selectedProductsId = value;
  }
  @Input() parentForm: FormGroup;
  @Output() handleSubmit = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter();
  @Output() handlePrint = new EventEmitter<any>();
  @Input() skipProductCheck = false;
  @Input() isDisabled = false;

  token: string = '';
  base_url: string = '';
  customers = [];
  recipients = [];
  addresses = [];
  address = [];
  paymentTerms = [];
  products = [];
  taxRates = [];
  productFamilies = [];
  units = [];
  locations = [];
  selectedProduct = '';
  employees = [];

  customerAddressMap = {};
  unitMap = {};
  productFamiliesMap = {};
  taxMap = {};
  includeTax = true;

  timeout: any = null;
  countdown: number = 0;
  hasLoaded: boolean = false;

  statusOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  numericStatusOptions = [
    { label: 'Open', value: 1 },
    { label: 'Close', value: 0 },
  ];

  salesOrderStatus = [
    { label: 'Open', value: 0 },
    { label: 'Close', value: 1 },
  ];
  SelectProductModalRef: NgbModalRef;
  draftProductsId = []; // Only contains id which is yet to be saved
  selectedProductsId = []; // Only contains id which is saved
  draftProducts = []; // Contains product detail which is yet to be saved
  selectedProducts = []; // Contains product detail which is saved
  visibleProducts = [];
  searchSelectedProducts = [];
  productSearchInput: string = '';

  boxQuantityCalcMode: Boolean = false;
  currentBoxIndex: Number = -1;

  subscription: Subscription;

  constructor(
    private customerHttp: CustomersHttpService,
    private paymentHttp: PaymentHttpService,
    private productHttp: ProductHttpService,
    private taxHttp: TaxHttpService,
    private unitHttp: UnitHttpService,
    private locationHttp: LocationHttpService,
    private modal: NgbModal,
    private appService: AppService,
    private loadingBar: LoadingBarService,
    private companyHttp: CompanyHttpService,
    private employeeHttp: EmployeeHttpService,
    private changeDetector: ChangeDetectorRef,
    private receiveNotesHttp:ReceiveNotesHttpService
  ) {}

  ngOnInit(): void {
    this.onLoad();
    this.fetchPaymentTerms();
    this.getLocation(),
    this.subscription = this.appService.formChangeEvent().subscribe((res) => {
      if (res && res.recalculate) {
        setTimeout(() => {
          this.calculateValues();
        }, 200);
      }
    });
    this.parentForm.controls['customer_id'].valueChanges.subscribe((value) => {
      if (value) {
        this.fetchReceipeints(value);
        this.fetchAddresses(value);
      } else {
        this.recipients = [];
        this.addresses = [];
        this.parentForm.controls.recipient_id.setValue('');
        this.parentForm.controls.address_id.setValue('');
      }
    });
    this.parentForm.controls['discount_rate'].valueChanges.subscribe(() => {
      this.calculateValues();
    });
  }


   getLocation(){
    this.locationHttp.fetchAll().pipe(
      map((response: any) => {
          this.locations = response;
      }),
      catchError((err) => {
        console.log(err);
        return EMPTY;
      })
    )
    .subscribe();
   }



  onLoad() {
    this.hasLoaded = false;
    this.loadingBar.start();

    // Get base_url from environment variables and easerp_token from localStorage:
    this.base_url = environment.BASE_URL;
    this.token = localStorage.getItem('easerp_token');

    // Get everything else:
    combineLatest([
      this.customerHttp.fetchAll({}),
      this.productHttp.fetchAll(),
      this.unitHttp.fetchAll(),
      this.productHttp.fetchProductFamilies(),
      this.taxHttp.fetchAll(),
      
      this.companyHttp.fetch(),
      this.employeeHttp.fetchAll(),
    ]).subscribe(
      ([
        customers,
        products,
        units,
        productFamilies,
        taxes,
        company,
        employees
      ]) => {
  
        this.customers = customers;
        this.parseCustomerWithAddress(customers);
        this.products = products.map(x => {
          x.selectorName = x.product_name + (x.stock ? ` (${x.stock.qty} pcs)` : '');
          return x;
        });
      

        this.searchSelectedProducts = this.products;
      
        this.visibleProducts = products;

        this.taxRates = taxes;
        taxes.forEach((tax) => {
          this.taxMap[tax.id] = tax.rate;
        });
        this.calculateValues();

        this.units = units;
        units.forEach((single) => {
          this.unitMap[single.unit_id] = single.unit_code;
        });
        // this.locations = locations;

        
        this.productFamilies = productFamilies;
        productFamilies.forEach((single) => {
          this.productFamiliesMap[single.product_family_id] =
            single.product_family_desc;
        });

        this.includeTax = company.tax_include;

        this.employees = employees.map((x: any) => x.full_name = x.first_name + ' ' + x.last_name);

        this.calculateValues();

        this.hasLoaded = true;
        this.loadingBar.stop();
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  fetchReceipeints(id) {
    this.customerHttp
      .fetchReceipeients(id)
      .pipe(
        map((response: any) => {
          this.recipients = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  fetchAddresses(id) {
    this.customerHttp
      .fetchAddresses(id)
      .pipe(
        map((response: any) => {
          this.addresses = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  shouldShow() {
    return (
      this.deliveryNotes.length > 0 ||
      this.salesOrders.length > 0 ||
      this.invoices.length > 0
    );
  }

  onImportItemsClick() {
    const uploadReceiveNoteInput = document.getElementById('upload-receive-note');
    uploadReceiveNoteInput.click();
  }



  selectProduct(event) {
    if (event) {
      this.ngSelectComponent.handleClearClick();
       this.newSelectProductRow(event);
      this.saveSelectProductModal();
    }
  }
  importReceiveNoteProducts(e: any) {
    const fileUploaded = e.target.files[0];

    const formData = new FormData();
    formData.append('file', fileUploaded);
    this.receiveNotesHttp
        .importProductsFromReceiveNote(formData)
        .pipe(
          map((response: any) => {
            response.forEach((x: any) => {
               this.newSelectProductRow(x);
              this.saveSelectProductModal();
            });
          }
        )
      )
      .subscribe();
  }

  fetchPaymentTerms() {
    if (!this.hidePaymentTerm) {
      this.paymentHttp
        .fetchPaymentTerms()
        .pipe(
          map((response: any) => {
            this.paymentTerms = response;
            this.parentForm.controls.payment_term_id.setValue(response[0].id);
          }),
          catchError((err) => {
            console.log(err);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  parseCustomerWithAddress(customers) {
    customers.forEach((customer: any) => {
      this.customerAddressMap[customer.customer_id] = customer.address;
    });
  }

  openSelectProductModal(content) {
    this.SelectProductModalRef = this.modal.open(content, {
      size: 'xl',
      scrollable: true,
    });
  }

  closeSelectProductModal() {
    this.draftProducts = [...this.selectedProducts];
    this.draftProductsId = [...this.selectedProductsId];
    this.SelectProductModalRef.close();
  }

  selectProductRow(product, shouldClose: boolean = false, searchType?: string) {
    const productIndex = this.selectedProductsId.indexOf(product.product_id);
    const PVoS = product.vat_on_sale;
    if (productIndex === -1) {
      this.draftProductsId.push(product.product_id);
      const data = {
        apply_tax: PVoS ? PVoS.id : this.taxRates[0].id,
        apply_tax_value: PVoS ? PVoS.rate : this.taxRates[0].rate,
        custom_price: false,
        disc_percent: '0.00',
        inventory_location_id: product.default_delivery_location ? product.default_delivery_location.location_id : this.locations[0].location_id,
        inventory_location_desc: product.default_delivery_location ? product.default_delivery_location.location_desc : this.locations[0].location_desc,
        inventory_tracking: true,
        product_id: product.product_id,
        product_name: product.product_name,
        product_ordered_desc: product.product_desc,
        product_desc: product.product_desc,
        product_stocks: product.stock.qty,
        qty_per_box: product.qty_per_box || 0,
        box_qty: searchType === 'box' ? 1 : 0,
        qty: searchType === 'box' ? product.qty_per_box : 1,
        stock_amount: '-1.00',
        straight_line_sum: product.sales_price,
        sub_total: formatNumber(product.sales_price),
        unit_amount: product.sales_price,
        hide: 0,
        profit_margin: {
          view: product.view,
          percent: product.percent,
          amount: product.amount,
          total: 0,
        },
      };
      this.draftProducts.push(data);

      this.fetchProductPrice(
        data,
        this.draftProducts.length - 1,
        true,
        shouldClose
      );
    } else {
      // If the product or product box is already on the selected products list, just increase the qty by 1:
      if (searchType === 'box') {
        this.onChangeTableData(this.selectedProducts[productIndex].box_qty + 1, productIndex, 'box_qty');
      } else {
        this.onChangeTableData(this.selectedProducts[productIndex].qty + 1, productIndex, 'qty');
      }
    }
  }



  newSelectProductRow(product, shouldClose = false) {
    const index = this.draftProductsId.indexOf(product.product_id);
    const PVoS = product.vat_on_sale;
    if (index === -1) {
      this.draftProductsId.push(product.product_id);
      const data = {
        apply_tax: PVoS ? PVoS.id : this.taxRates[0].id,
        apply_tax_value: PVoS ? PVoS.rate : this.taxRates[0].rate,
        custom_price: false,
        disc_percent: '0.00',
        inventory_location_id: product.default_delivery_location ? product.default_delivery_location.location_id : this.locations[0].location_id,
        inventory_location_desc: product.default_delivery_location ? product.default_delivery_location.location_desc : this.locations[0].location_desc,
        inventory_tracking: true,
        product_id: product.product_id,
        product_name: product.product_name,
        product_ordered_desc: product.product_desc,
        product_desc: product.product_desc,
        qty_per_box: product.qty_per_box || 0,
        box_qty: 0,
        qty: product.receive_qty,
        stock_amount: '-1.00',
        straight_line_sum: product.sales_price,
        sub_total: product.sales_price,
        unit_amount: product.sales_price,
        hide: 0,
        profit_margin: {
          view: product.view,
          percent: product.percent,
          amount: product.amount,
          total: 0,
        },
      };
      this.draftProducts.push(data);

      this.fetchProductPrice(
        data,
        this.draftProducts.length - 1,
        true,
        shouldClose
      );
    } else {
      this.draftProducts.splice(index, 1);
      this.draftProductsId.splice(index, 1);
    }
  }

  removeProductRow(index: number) {
    Swal.fire({
      title: 'Remove product row',
      text: 'Are you sure you want to remove this product row?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#727CF5',
      showDenyButton: true,
      denyButtonText: 'Deny',
      denyButtonColor: '#ff3366'
    }).then(result => {
      if (result.isConfirmed) {
        this.selectedProducts.splice(index, 1);
        this.selectedProductsId.splice(index, 1);
        this.calculateValues();
      }
    });
  }

  saveSelectProductModal() {
    this.selectedProducts = [...this.selectedProducts, ...this.draftProducts];
    this.selectedProductsId = [
      ...this.selectedProductsId,
      ...this.draftProductsId,
    ];
    this.draftProducts = [];
    this.draftProductsId = [];
    this.calculateValues();
    if (this.SelectProductModalRef) {
      this.SelectProductModalRef.close();
    }
  }

  calculateValues() {
    if (this.includeList.length === 0) {
      return;
    }
    let subtotal = 0;
    let includeSubTotal = 0;
    let salestax = 0;
    let includeSalesTax = 0;
    let selectedProducts = [...this.selectedProducts];
    let totalMargin = 0;

    selectedProducts.forEach((product, i) => {
      let productSubTotal = 0;
      const taxRate = Number(this.taxMap[product.apply_tax] || '0');
      const discountRate = Number(product.disc_percent || '0');
      const unitAmount = Number(product.unit_amount || '0');

      const box_qty = Number(product.box_qty || 0);
      // const qty = Number(product.qty || '0');
      const unitQty = Number(product.qty || '0');
      const qty = (product.qty_per_box && product.qty_per_box > 0 && this.boxQuantityCalcMode && this.currentBoxIndex === i) 
        ? 
          product.qty_per_box * box_qty 
        : 
          unitQty
      ;
      // const qty = (product.qty_per_box && product.qty_per_box > 0 && this.boxQuantityCalcMode && this.currentBoxIndex === i) 
      //   ? 
      //     product.qty_per_box * box_qty 
      //   : 
      //     unitQty
      // ;

      const rate = taxRate / 100;
      productSubTotal =
        qty * unitAmount - (discountRate / 100) * qty * unitAmount;

      subtotal += productSubTotal;

      salestax += (taxRate / 100) * productSubTotal;

      const includeProductSubTotal = productSubTotal / (1 + rate);
      includeSubTotal += includeProductSubTotal;
      includeSalesTax += productSubTotal - includeProductSubTotal;

      this.selectedProducts[i].sub_total = formatNumber(productSubTotal);
      this.selectedProducts[i].apply_tax_value = this.taxMap[product.apply_tax];

      // Product margin displaying has been removed for sales order:
      if (this.source !== 'Order') {
        let productMargin = product.profit_margin
        ? Number(product.profit_margin.amount)
        : 0;

        let totalProductMargin = productMargin * qty;
        totalMargin += totalProductMargin;

        this.selectedProducts[i].profit_margin.total = totalProductMargin;
      }
    });

    const discountRate = Number(
      this.parentForm.controls.discount_rate.value || '0'
    );

    let discountAmount = '';
    let totalAmountBeforeTax = 0;
    let totalAmountAfterTax = '';
    (totalAmountBeforeTax + salestax).toFixed(2);

    if (!this.includeTax) {
      discountAmount = ((subtotal * discountRate) / 100).toFixed(2);
      totalAmountBeforeTax = subtotal - Number(discountAmount);
      totalAmountAfterTax = (totalAmountBeforeTax + salestax).toFixed(2);
    } else {
      subtotal = includeSubTotal;
      salestax = includeSalesTax;
      discountAmount = ((subtotal * discountRate) / 100).toFixed(2);
      totalAmountBeforeTax = subtotal - Number(discountAmount);
      totalAmountAfterTax = (totalAmountBeforeTax + includeSalesTax).toFixed(2);
    }

    this.parentForm.controls.discount_amount.setValue(discountAmount);
    if (this.parentForm.controls.total_margin) {
      this.parentForm.controls.total_margin.setValue(totalMargin);
    }

    if (this.includeList.includes('total_amount')) {
      this.parentForm.controls.total_amount.setValue(totalAmountAfterTax);
    } else {
      this.parentForm.controls.grand_total.setValue(totalAmountAfterTax);
    }

    if (this.includeList.includes('sub_total')) {
      this.parentForm.controls.sub_total.setValue(subtotal.toFixed(2));
    } else {
      this.parentForm.controls.subtotal.setValue(subtotal.toFixed(2));
    }

    if (this.includeList.includes('tax_amount')) {
      this.parentForm.controls.tax_amount.setValue(salestax.toFixed(2));
    } else {
      this.parentForm.controls.sales_tax.setValue(salestax.toFixed(2));
    }
  }

  onChangeTableData(e, i, name) {
    const selectedProduct = this.selectedProducts[i];
    selectedProduct[name] = e;
    this.currentBoxIndex = i;

    if (name === 'box_qty') {
      this.boxQuantityCalcMode = true;
      selectedProduct.qty = e * selectedProduct.qty_per_box;
    }

    if (name === 'qty') {
      this.boxQuantityCalcMode = false;
      selectedProduct.box_qty = 0;
    }
    // To be added later:
    // Add a refresh / revert back button which brings back the original price:
    // if (name === 'qty') {
    //   this.fetchProductPrice(this.selectedProducts[i], i, false, false);
    // }
    this.calculateValues();
  }

  onUpdatePrice() {
    Swal.fire({
      title: 'Update product prices',
      text: 'Are you sure you want to update the product prices to the latest?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#727CF5',
      showDenyButton: true,
      denyButtonText: 'Deny',
      denyButtonColor: '#ff3366'
    }).then(result => {
      if (result.isConfirmed) {
        for (let i = 0; i < this.selectedProducts.length; i++) {
          const filter = {
            customer_id: this.parentForm.value.customer_id,
            product_id: this.selectedProducts[i].product_id,
          };

          this.productHttp
            .getProductPrice(filter)
            .pipe(
              map((response: any) => {
                this.selectedProducts[i].unit_amount = response.price || 0;
                this.calculateValues();
                successAlert('Price updated successfully!', 'SUCCESS');
              }),
              catchError((err) => {
                this.loadingBar.stop();
                return EMPTY;
              })
            )
            .subscribe();
        }
      }
    });
  }

  onSubmit() {
    if (this.parentForm?.valid && this.countdown === 0) {
      const data = {
        ...this.parentForm.value,
        salesorder_id: this.id,
        address_id:
          this.customerAddressMap[this.parentForm.value.customer_id]
            ?.address_id,
        choices: this.selectedProducts,
        documents: [],
        tax_amount: this.excludeList.includes('tax_amount')
          ? this.parentForm.value.tax_percentage
          : this.parentForm.value.tax_amount,
      };

      if (!data.recipient_id) {
        data.recipient_id = '';
      }

      // Check if there is any price of 0 or not.
      // If yes, give a warning to user and only submit when user confirms it:
      const noOfZeroValues: number = data.choices.filter(x => Number(x.sub_total) === 0).length;
      if (noOfZeroValues > 0) {
        Swal.fire({
          title: `${noOfZeroValues} of the selected products have price of 0.`,
          text: ' Do you still want to proceed?',
          confirmButtonText: 'Confirm',
          confirmButtonColor: '#727CF5',
          showDenyButton: true,
          denyButtonText: 'Deny',
          denyButtonColor: '#ff3366',
        }).then((result) => {
          if (result.isConfirmed) {
            // Send for submitting:
            this.handleSubmit.emit(data);
          } else if (result.isDenied) {
            Swal.fire({
              title: 'Have a look once more',
              icon: 'info',
              timer: 3000
            });
          }
        });
      } else {
        // If there is no any pricing of 0:
        this.handleSubmit.emit(data);
      }
      // Prevent double clicking of the submit button:
      this.countdown = 2000;
      setTimeout(() => this.countdown = 0, 2000);
    }
  }

  goBack() {
    this.handleCancel.emit();
  }

  // product search start
  onClickTableSearch() {
    const inputNode = document.getElementById('product-search-input');
    inputNode.focus();
  }

  changeProductFilter(product) {
    this.productSelector.close();
    if (product) {
      localStorage.setItem('invoiceProductSearchTerm', JSON.stringify(product.product_id));
      this.selectProductRow(product, true);
    }
    
    this.productSearchInput = '';
    this.searchSelectedProducts = this.products;
  }

  fetchLocalStorageProductId() {
    const productId = JSON.parse(localStorage.getItem('invoiceProductSearchTerm'));
    if (productId) {
      return productId;
    } else {
      return EMPTY;
    }
  }

  searchProduct(e: any) {
    let filter = e.target.value.toLowerCase();
    this.searchSelectedProducts = this.products.filter(p => p.product_name.toLowerCase().includes(filter));
  }

  clearProductSelection() {
    localStorage.removeItem('invoiceProductSearchTerm');    
    this.changeProductFilter('');  
  }
  // product search end

  onAddBarcodeValue(event) {
    // Prevent affecting any other things in the app when enter key is pressed:
    if (event.which !== 13) {
      clearTimeout(this.timeout);
      if (event) {
        this.timeout = setTimeout(() => {
          const { value } = event.target;
          const requiredProduct = this.products.find(x => x.barcode === value);
          if (requiredProduct) {
            this.selectProductRow(requiredProduct, true);
          } else {
            const requiredProductBox = this.products.find(x => x.box_barcode === value);
            if (requiredProductBox) {
              this.selectProductRow(requiredProductBox, true, 'box');
            } else {
              warningAlert('Product or Product Box not found!!', 'Check the barcode again');
            }
          }
          this.parentForm.get('bar').setValue('');
        }, 500);
      }
    }
  }

  selectDraftProduct(event) {
    if (event) {
      this.selectProductRow(event);
    }
  }

  changeProduct(event) {
    const { value } = event.target;
    if (value >= 0) {
      const product = this.products[value];
      const index = this.draftProductsId.indexOf(product.product_id);
      if (index === -1) {
        this.selectProductRow(product);
        this.selectedProduct = '';
        this.saveSelectProductModal();
      }
    }
  }

  fetchProductPrice(product, index, isNew, shouldClose) {
    const customer_id = this.parentForm.value.customer_id;

    if (customer_id) {
      const filter = {
        customer_id: this.parentForm.value.customer_id,
        product_id: product.product_id,
        qty: product.qty,
      };

      if (shouldClose) {
        this.loadingBar.start();
      }

      this.productHttp
        .getProductPrice(filter)
        .pipe(
          map((response: any) => {
            let isCustom = false;
            if (response.min_qty) {
              isCustom = true;
            }

            if (isNew) {
              this.draftProducts[index].custom_price = isCustom;
              this.draftProducts[index].unit_amount = response.price;
              this.draftProducts[index].box_price = response.box_price;
              if (shouldClose) {
                this.saveSelectProductModal();
              }
            } else {
              this.selectedProducts[index].custom_price = isCustom;
              this.selectedProducts[index].unit_amount = response.price;
              this.selectedProducts[index].box_price = response.box_price;
              this.calculateValues();
            }
            this.loadingBar.stop();
          }),
          catchError((err) => {
            this.loadingBar.stop();
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  onChangeSearch(e) {
    const products = [];
    this.products.forEach((product) => {
      if (
        product.product_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        products.push(product);
      }
    });

    this.visibleProducts = products;
  }

  print(id: Number) {
    this.handlePrint.emit(id);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
