import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { map } from 'rxjs/operators';
import { CompanyHttpService } from '@services';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isLoading: boolean;

  constructor(
    private router: Router,
    private companyHttp: CompanyHttpService
  ) { 

    // Spinner for lazyload modules
    router.events.forEach((event) => { 
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });

    
  }

  ngOnInit(): void {
    // Store date format in local storage, to be later used by date pickers:
    this.companyHttp
      .fetch()
      .pipe(
        map((res: any) => {
          localStorage.setItem('company_date_format', JSON.stringify(res.date_format));
        })
      )
      .subscribe();
  }

}
