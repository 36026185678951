import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class CustomersHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}sales/customer-list`, query);
    return this.httpClient.get(url);
  }

  public fetchAllWithFilter(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}sales/customers`, query);
    return this.httpClient.get(url);
  }

  public remove(id): Observable<any> {
    return this.httpClient.get(`${base_url}sales/customers/${id}/delete`);
  }

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');

    const url = `${base_url}sales/customers/export/customer?token=${token}`;

    window.open(url, '_blank');
  }

  public initialiseImport(data): Observable<any> {
    return this.httpClient.post(
      `${base_url}sales/customers/import/customer`,
      data
    );
  }

  public confirmImport(data): Observable<any> {
    return this.httpClient.post(`${base_url}sales/import-customers`, data);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/customers`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.post(`${base_url}api/customers-update/${id}`, data);
  }

  public setCustomerPassword(id: number, query: any): Observable<any> {
    return this.httpClient.put(`${base_url}api/v1/customers/${id}/change-password`, query);
  }

  public fetchById(id): Observable<unknown> {
    return this.httpClient.get(`${base_url}api/customer-by-id/${id}`);
  }

  public fetchCustomerProductsReport(query: {
    [key: string]: any;
  }): Observable<any> {
    const url = parseQuery(
      `${base_url}report/customer-reports`,
      query,
      'product_id',
      'product_id[]'
    );
    return this.httpClient.get(url);
  }

  public fetchReceipeients(id): Observable<unknown> {
    return this.httpClient.get(`${base_url}api/recipients/${id}`);
  }

  public fetchAddresses(id): Observable<unknown> {
    return this.httpClient.get(`${base_url}api/addresses/${id}`);
  }

  // For Customer Analytics
  public fetchCustomerDuePayment(sort_field: string, sort_by: string, customer_group: string): Observable<any> {
    return this.httpClient.get(`${base_url}report/v2/invoice/-/customer-due-payment?sort_field=${sort_field}&sort_by=${sort_by}&customer_group=${customer_group}`);
  }

  public fetchSnapShot(productName: any, date: any): Observable<any> {
    return this.httpClient.get(`${base_url}report/stock-snapshots?date=${date}&product_name=${productName}`);
  }
  
}
