import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'normal-upload',
  templateUrl: './normal-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NormalUpload),
    },
  ],
})
export class NormalUpload implements OnInit {
  @Input() formControlName = '';
  @Input() label = '';
  @Input() parentForm: FormGroup;
  @Output() fileHolder = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onChange: any = () => {};
  onTouch: any = () => {};
  val = ''; // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.querySelector(
      '#normalUpload'
    ) as HTMLElement;
    element.click();
  }

  changeFile(e) {
    if (e.target.files && e.target.files.length) {
      this.fileHolder.emit(e.target.files[0]);
    } else {
      this.fileHolder.emit(null);
    }
  }
}
