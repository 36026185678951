import {
  Component,
  OnInit,
  forwardRef,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';

import * as dateUtil from '@utils/date';

import { NgbModal, NgbModalRef, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePicker),
    },
  ],
})
export class DateRangePicker implements OnInit {
  @Output() handleSelect = new EventEmitter<any>();
  @Input() isDropdown = false;
  @Input() dropdownItems = [];

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  DateRangeModalRef: NgbModalRef;

  constructor(private modal: NgbModal) {}
  ngOnInit() {}
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      const start = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
      const end = `${date.year}-${date.month}-${date.day}`;
      this.searchDate(start, end);
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  openDateRangeModal(content) {
    this.DateRangeModalRef = this.modal.open(content, {
      size: 'l',
      scrollable: true,
    });
  }

  closeDateRangeModal() {
    this.DateRangeModalRef.close();
  }

  searchDate(fromDate, toDate) {
    this.closeDateRangeModal();
    this.handleSelect.emit({
      start: fromDate,
      end: toDate,
    });
  }

  selectToday() {
    const today = dateUtil.getToday();
    this.searchDate(today, today);
  }

  selectThisWeek() {
    const start = dateUtil.getWeekStart();
    const end = dateUtil.getWeekEnd();
    this.searchDate(start, end);
  }

  selectThisMonth() {
    const start = dateUtil.getMonthStart();
    const end = dateUtil.getMonthEnd();
    this.searchDate(start, end);
  }

  selectThisYear() {
    const start = dateUtil.getYearStart();
    const end = dateUtil.getYearEnd();
    this.searchDate(start, end);
  }

  selectLastWeek() {
    const start = dateUtil.getLastWeekStart();
    const end = dateUtil.getLastWeekEnd();
    this.searchDate(start, end);
  }

  selectLastMonth() {
    const start = dateUtil.getLastMonthStart();
    const end = dateUtil.getLastMonthEnd();
    this.searchDate(start, end);
  }

  selectLastYear() {
    const start = dateUtil.getLastYearStart();
    const end = dateUtil.getLastYearEnd();
    this.searchDate(start, end);
  }

  clear() {
    this.searchDate('', '');
  }
}
