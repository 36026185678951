import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class StatementHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetch(id): Observable<any> {
    return this.httpClient.get(
      `${base_url}report/statements?customer_id=${id}`
    );
  }

  public sendEmail(id, data): Observable<any> {
    return this.httpClient.post(`${base_url}report/statements/${id}`, data);
  }
}
