import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@views//auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'sales-order',
        loadChildren: () =>
          import('@views/sales-order/sales-order.module').then(
            (m) => m.SalesOrderModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('@views/products/products.module').then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('@views/invoices/invoices.module').then(
            (m) => m.InvoicesModule
          ),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('@views/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
      },
      {
        path: 'delivery-notes',
        loadChildren: () =>
          import('@views/delivery-notes/delivery-notes.module').then(
            (m) => m.DeliveryNotesModule
          ),
      },
      {
        path: 'price-rule',
        loadChildren: () =>
          import('@views/price-rule/price-rule.module').then(
            (m) => m.PriceRuleModule
          ),
      },
      {
        path: 'credit-memo',
        loadChildren: () =>
          import('@views/credit-memo/credit-memo.module').then(
            (m) => m.CreditMemoModule
          ),
      },
      {
        path: 'sales-receipt',
        loadChildren: () =>
          import('@views/sales-receipt/sales-receipt.module').then(
            (m) => m.SalesReceiptModule
          ),
      },
      {
        path: 'inventories',
        loadChildren: () =>
          import('@views/inventories/inventories.module').then(
            (m) => m.InventoriesModule
          ),
      },
      {
        path: 'purchase',
        loadChildren: () =>
          import('@views/purchase/purchase.module').then(
            (m) => m.PurchaseModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('@views/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'accounting',
        loadChildren: () =>
          import('@views/accounting/accounting.module').then(
            (m) => m.AccountingModule
          ),
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('@views/marketing/marketing.module').then(
            (m) => m.MarketingModule
          ),
      },
      {
        path: 'hrm',
        loadChildren: () =>
          import('@views/hrm/hrm.module').then((m) => m.HRMModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('@views/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'edit-profile',
        loadChildren: () =>
          import('@views/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      { path: '',
      component:DashboardComponent,
     },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
