import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Injectable,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

// Get the date format directly from local storage:
const date_format = JSON.parse(localStorage.getItem('company_date_format')) || '';

@Injectable()
export class CustomDateAdapter {

  fromModel(value: string): NgbDateStruct {
    if (!value) return null;

    if (date_format === 'm/d/Y') {
      let parts = value.split('/');
      return { year: +parts[2], month: +parts[0], day: +parts[1] };
    } else if (date_format === 'd/m/Y') {
      let parts = value.split('/');
      return { year: +parts[2], month: +parts[1], day: +parts[0] };
    } else if (date_format === 'Y/m/d') {
      let parts = value.split('/');
      return { year: +parts[0], month: +parts[1], day: +parts[2] };
    } else if (date_format === 'd.m.Y') {
      let parts = value.split('.');
      return { year: +parts[2], month: +parts[1], day: +parts[0] };
    } else if (date_format === 'Y-m-d') {
      let parts = value.split('-');
      return { year: +parts[0], month: +parts[1], day: +parts[2] };
    }    
    // Original code (2 lines):
    // let parts = value.split('.');
    // return { year: +parts[2], month: +parts[1], day: +parts[0] }
  }

  toModel(date: NgbDateStruct): string {
    if (date_format === 'm/d/Y') {
      return date
      ? date.month +
          '/' +
          ('0' + date.day).slice(-2) +
          '/' +
          ('0' + date.year).slice(-2)
      : null;
    } else if (date_format === 'd/m/Y') {
      return date
      ? date.day +
          '/' +
          ('0' + date.month).slice(-2) +
          '/' +
          ('0' + date.year).slice(-2)
      : null;
    } else if (date_format === 'Y/m/d') {
      return date
      ? date.year +
          '/' +
          ('0' + date.month).slice(-2) +
          '/' +
          ('0' + date.day).slice(-2)
      : null;
    } else if (date_format === 'd.m.Y') {
      return date
      ? date.day +
          '.' +
          ('0' + date.month).slice(-2) +
          '.' +
          ('0' + date.year).slice(-2)
      : null;
    } else if (date_format === 'Y-m-d') {
      return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : null;
    }
    // Original code (7 lines):
    // return date
    //   ? date.year +
    //       '-' +
    //       ('0' + date.month).slice(-2) +
    //       '-' +
    //       ('0' + date.day).slice(-2)
    //   : null;
  }
}

@Injectable()
export class CustomDateParserFormatter {

  parse(value: string): NgbDateStruct {
    if (!value) return null;
    
    if (date_format === 'm/d/Y') {
      let parts = value.split('/');
      return {
        year: +parts[2],
        month: +parts[0],
        day: +parts[1],
      } as NgbDateStruct;
    } else if (date_format === 'd/m/Y') {
      let parts = value.split('/');
      return {
        year: +parts[2],
        month: +parts[1],
        day: +parts[0],
      } as NgbDateStruct;
    } else if (date_format === 'Y/m/d') {
      let parts = value.split('/');
      return {
        year: +parts[0],
        month: +parts[1],
        day: +parts[2],
      } as NgbDateStruct;
    } else if (date_format === 'd.m.Y') {
      let parts = value.split('.');
      return {
        year: +parts[2],
        month: +parts[1],
        day: +parts[0],
      } as NgbDateStruct;
    } else if (date_format === 'Y-m-d') {
      let parts = value.split('-');
      return {
        year: +parts[0],
        month: +parts[1],
        day: +parts[2],
      } as NgbDateStruct;
    }
    // Original code (6 lines);
    // let parts = value.split('-');
    // return {
    //   year: +parts[0],
    //   month: +parts[1],
    //   day: +parts[2],
    // } as NgbDateStruct;
  }
  format(date: NgbDateStruct): string {
    if (date_format === 'm/d/Y') {
      return date
      ? date.month +
          '/' +
          ('0' + date.day).slice(-2) +
          '/' +
          ('0' + date.year).slice(-2)
      : null;
    } else if (date_format === 'd/m/Y') {
      return date
      ? date.day +
          '/' +
          ('0' + date.month).slice(-2) +
          '/' +
          ('0' + date.year).slice(-2)
      : null;
    } else if (date_format === 'Y/m/d') {
      return date
      ? date.year +
          '/' +
          ('0' + date.month).slice(-2) +
          '/' +
          ('0' + date.day).slice(-2)
      : null;
    } else if (date_format === 'd.m.Y') {
      return date
      ? date.day +
          '.' +
          ('0' + date.month).slice(-2) +
          '.' +
          ('0' + date.year).slice(-2)
      : null;
    } else if (date_format === 'Y-m-d') {
      return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : null;
    }
  }
  // Original code (9 lines):
  // format(date: NgbDateStruct): string {
  //   return date
  //     ? date.year +
  //         '-' +
  //         ('0' + date.month).slice(-2) +
  //         '-' +
  //         ('0' + date.day).slice(-2)
  //     : null;
  // }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() formControlName = '';
  @Input() ngModel = '';
  @Input() isValid = false;
  @Input() clientError = '';
  @Input() serverError = '';
  @Input() label = '';
  @Input() isSmall = false;
  @Input() parentForm: FormGroup;
  @Input() isNormal = false;
  @Output() handleChange = new EventEmitter<any>();

  // For placeholder:
  dateFormat = date_format || '';

  constructor() {}

  ngOnInit(): void {}

  onChange: any = () => {};
  onTouch: any = () => {};
  val = ''; // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  changeDate(e) {
    this.handleChange.emit(e);
  }
}
