import Swal from 'sweetalert2/dist/sweetalert2.js';

export const errorAlert = (err, title?: string) => {
  Swal.fire({
    title: title || 'Something went wrong!!',
    text: err.error
      ? err.error.error_message || err.error.message
      : err.message,
    icon: 'error',
    confirmButtonText: 'Okay',
    timer: 3000,
  });
};

export const successAlert = (title, text) => {
  Swal.fire({
    title,
    text,
    icon: 'success',
    confirmButtonText: 'Okay',
    timer: 2000,
  });
};

export const warningAlert = (title, text) => {
  Swal.fire({
    title,
    text,
    icon: 'warning',
    confirmButtonText: 'Okay',
    timer: 3000,
  });
};

export const msgErrorAlert = (message) => {
  Swal.fire({
    title:'Error',
    text: message,
    icon: 'error',
    confirmButtonText: 'Okay',
    timer: 3000,
  });
};
