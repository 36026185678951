import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public eventFormChange = new BehaviorSubject<any>(null);

  triggerFormChange(data: any) {
    this.eventFormChange.next(data);
  }

  formChangeEvent() {
    return this.eventFormChange.asObservable();
  }
}
