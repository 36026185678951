import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import {
  LoginInterface,
  SignupInterface,
  ForgotPasswordInterface,
} from '@models/auth';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private httpClient: HttpClient) {}

  public login(credentials: LoginInterface): Observable<any> {
    return this.httpClient.post(`${base_url}api/login`, credentials);
  }

  public register(userInfo: SignupInterface): Observable<any> {
    return this.httpClient.post(`${base_url}api/v2/companies`, userInfo);
  }

  public forgotPassword(payload: ForgotPasswordInterface): Observable<any> {
    return this.httpClient.post(`${base_url}reset/email`, payload);
  }

  public isLoggedIn(): Observable<any> {
    return this.httpClient.get(`${base_url}api/is-login`);
  }
}
