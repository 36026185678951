import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class PriceRuleHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}api/price-rules`);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/price-rules/${id}`);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/price-rules`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/price-rules/${id}`, data);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/price-rules/${id}`);
  }
}
