import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class TemplateHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.httpClient.get(`${base_url}admin/templates/lists/all`);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}admin/templates`, data);
  }

  public upsert(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/templates`, data);
  }

  public fetchTemplate(id): Observable<any> {
    const token: string = localStorage.getItem('easerp_token');
    return this.httpClient.get(`${base_url}api/templates/${id}?token=token`);
  }

  public updateStatus(data): Observable<any> {
    return this.httpClient.put(
      `${base_url}admin/templates/${data.template_id}`,
      data
    );
  }
}
