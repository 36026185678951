import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class EInvoiceHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query): Observable<any> {
    const url = parseQuery(`${base_url}api/apix-invoices`, query);
    return this.httpClient.get(url);
  }

  public fetchSenders(): Observable<any> {
    return this.httpClient.get(`${base_url}api/apix-invoices-sender`);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/apix-invoices/${id}`);
  }

  public download(id): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}api/apix-invoices/${id}?token=${token}&q=download`;

    window.open(url, '_blank');
  }

  public update(id, data): Observable<any> {
    const requestHeaders = this.getDefaultHeaders();
    return this.httpClient.put(`${base_url}api/apix-invoices/${id}`, data, { headers: requestHeaders });
  }
  
  private getDefaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' based on your requirement
      // Add other headers as needed
    });
  }

  public getEinvoice(): Observable<any> {
    return this.httpClient.get(`${base_url}api/check-apix-customer`);
  }
}
