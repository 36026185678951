import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Directive,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Directive({
  selector: 'iframe',
})
@Component({
  selector: 'email-invoice',
  templateUrl: './modal.html',
  styleUrls: ['./modal.scss'],
})
export class EmailInvoice implements OnInit {
  @Input() innerHtml: '';
  @Input() data: {
    title: '';
    from: '';
    to: [];
    cc: [];
    message: '';
    subject: '';
    is_reminder: false;
  };
  @Input() previewUrl: '';

  cachedUrl: SafeResourceUrl;

  @Output() handleSubmit = new EventEmitter();
  @Output() handleCancel = new EventEmitter();
  @Output() handleChange = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  sanitize() {
    if (!this.cachedUrl) {
      this.cachedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.previewUrl
      );
    }
    return this.cachedUrl;
  }

  onSubmit() {
    this.handleSubmit.emit();
  }

  onCancel() {
    this.handleCancel.emit();
  }

  onChange(e) {
    const { name, value } = e.target;
    this.handleChange.emit({ name, value });
  }

  onChecked(e) {
    const { name, checked } = e.target;
    this.handleChange.emit({ name, value: checked });
  }
}
