import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { environment } from "@env/environment";
import { parseQuery } from "@utils/query";

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: "root",
})
export class ExpensesHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query): Observable<any> {
    const url = parseQuery(`${base_url}api/expenses`, query);
    return this.httpClient.get(url);
  }

  public fetchStats(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/expense-header`, query);
    return this.httpClient.get(url);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/expenses/${id}`);
  }

  public fetchByAmount(amount): Observable<any> {
    return this.httpClient.get(`${base_url}api/expenses-by-amount/${amount}`);
  }

  public add(data): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.httpClient.post(`${base_url}api/expenses`, data, { headers });
  }

  public add1(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/expenses`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/expenses/${id}`, data);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}/api/expenses/${id}`);
  }

  public fetchVendorTax(id): Observable<any> {
    return this.httpClient.get(`${base_url}/api/expenses/vendor/${id}`);
  }

  public fetchCategories(): Observable<any> {
    return this.httpClient.get(`${base_url}api/expense-category-list`);
  }

  public fetchSaltedge(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/saltedge/v2/transactions/${id}`);
  }

  public attach(expenseId, imageId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/attach-receipt/${expenseId}/${imageId}?module=expense`
    );
  }

  public detach(expenseId, imageId): Observable<any> {
    return this.httpClient.get(
      `${base_url}api/detach-receipt/${expenseId}/${imageId}?module=expense`
    );
  }

  public cancelExpense(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/expenses-close`, data);
  }

  public closeBulk(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/expenses-close-bulk`, data);
  }
}
