import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';

import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EmployeeFormComponent),
    },
  ],
})
export class EmployeeFormComponent implements OnInit {
  @Input() isSubmitting = false;
  @Input() parentForm: FormGroup;
  @Output() handleFileChange = new EventEmitter<any>();
  @Output() handleDocumentChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  fileHolder = null;
  documentHolder = null;

  statusOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  changeFile(file) {
    this.handleFileChange.emit(file);
  }

  changeDocument(doc) {
    this.handleDocumentChange.emit(doc);
  }
}
