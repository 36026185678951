import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class InvoicesHttpService {
  private directHttp: HttpClient;
  constructor(private httpClient: HttpClient, private handler: HttpBackend) {
    this.directHttp = new HttpClient(handler);
  }

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/invoices`, query);
    return this.httpClient.get(url);
  }

  public fetchStats(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/invoice-header`, query);
    return this.httpClient.get(url);
  }

  public fetchHtml(id): Observable<any> {
    const token: string = localStorage.getItem('easerp_token');
    return this.directHttp.get(
      `${base_url}proxy/email/${id}/invoice?token=${token}`
    );
  }

  public print(id): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}sales/invoices/${id}/print?token=${token}`;

    window.open(url, '_blank');
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/invoices`, data);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/invoices/${id}`);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/invoices/${id}`, data);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}/api/invoices/${id}`);
  }

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');

    const url = `${base_url}sales/invoices/export?token=${token}`;

    window.open(url, '_blank');
  }

  public email(body): Observable<any> {
    return this.httpClient.post(`${base_url}sales/invoice-email`, body);
  }

  public eInvoice(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/invoices/${id}/apix-invoices`);
  }

  public fetchReports(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}report/invoice-reports`, query);
    return this.httpClient.get(url);
  }

  public fetchStatements(): Observable<any> {
    return this.httpClient.get(`${base_url}api/invoice/reports`);
  }

  // ## Invoice Analytics ##
  public averageHistoricalPayment(): Observable<any> {
    return this.httpClient.get(`${base_url}report/invoice/-/average-historical-payment`);
  }

  public invoiceDue(): Observable<any> {
    return this.httpClient.get(`${base_url}report/invoice/-/due`);
  }

  // ## End of Invoice Analytics ##
}
