import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class CountriesHttpService {
  constructor(private httpClient: HttpClient) {}

  public get(): Observable<any> {
    return this.httpClient.get(`${base_url}countries`);
  }
}
