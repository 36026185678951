import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class CreditMemoHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/credit-memos`, query);
    return this.httpClient.get(url);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/credit-memos`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/credit-memos/${id}`, data);
  }

  public fetchById(id): Observable<unknown> {
    return this.httpClient.get(`${base_url}api/credit-memos/${id}`);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/credit-memos/${id}`);
  }

  public print(id): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}sales/credit-memo/${id}/print?token=${token}`;

    window.open(url, '_blank');
  }
}
