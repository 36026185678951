import { MenuItem } from './menu.model';

export function fetchMenues(enabled): MenuItem[] {
  const menues: MenuItem[] = [];

  MENU.forEach((menu) => {
    if (enabled.includes(menu.label.toLowerCase())) {
      menues.push(menu);
    } else if (menu.label.toLowerCase() === 'settings') {
      menues.push(menu);
    }
  });

  return menues;
}

export const MENU: MenuItem[] = [
  {
    label: 'Sales',
    icon: 'shopping-cart',
    subItems: [
      {
        label: 'Products',
        link: '/products',
      },
      {
        label: 'Customers',
        link: '/customers',
      },
      {
        label: 'Sales Order',
        link: '/sales-order',
      },
      {
        label: 'Invoice',
        link: '/invoices',
      },
      {
        label: 'Delivery Note',
        link: '/delivery-notes',
      },
      {
        label: 'Credit Memo',
        link: '/credit-memo',
      },
      {
        label: 'Price List',
        link: '/price-rule',
      },
      {
        label: 'Sales Receipt',
        link: '/sales-receipt',
      },
    ],
  },
  {
    label: 'Inventory',
    icon: 'box',
    subItems: [
      {
        label: 'Storage Location',
        link: 'inventories/locations',
      },
      {
        label: 'Stocks',
        link: 'inventories/stocks',
      },
    ],
  },
  {
    label: 'Purchasing',
    icon: 'shopping-bag',
    subItems: [
      {
        label: 'EInvoice',
        link: 'purchase/einvoice',
      },
      {
        label: 'Receipts',
        link: 'purchase/receipts',
      },
      {
        label: 'Receive Note',
        link: 'purchase/receive-notes',
      },
      {
        label: 'Bill',
        link: 'purchase/bills',
      },
      {
        label: 'Purchase Order',
        link: 'purchase/purchase-order',
      },
      {
        label: 'Vendors',
        link: 'purchase/vendors',
      },
      {
        label: 'Expense',
        link: 'purchase/expenses',
      },
    ],
  },
  {
    label: 'Report',
    icon: 'file',
    subItems: [
      { label: 'Export Receipt', link: 'reports/apix-export' },
      { label: 'Export Invoice', link: 'reports/invoice-export' },
      { label: 'Payment Reports', link: 'reports/payments' },
      { label: 'Customer Usage', link: 'reports/customer-product' },
      { label: 'Product Usage', link: 'reports/product-usage-report' },
      { label: 'Product Margin', link: 'reports/product-margin-report' },
      { label: 'Invoice Margin', link: 'reports/invoice' },
      { label: 'Missed Invoices', link: 'reports/missed-invoices' },
      { label: 'Delivery Note Line', link: 'reports/delivery-note-line' },
      { label: 'Invoice Analytics', link: 'reports/invoice-analytics' },
      { label: 'Customer Analytics', link: 'reports/customer-analytics' },
      { label: 'Stocks Reports', link: 'reports/stocks' },
      { label: 'Stock Logs', link: 'reports/stock-log' },
      { label: 'Statement', link: 'reports/statement' },
      { label: 'Stock Snapshot', link: 'reports/stock-snapshot' }
    ],
  },
  {
    label: 'Accounting',
    icon: 'book-open',
    subItems: [
      {
        label: 'Transaction',
        link: 'accounting/saltedge',
      },
      {
        label: 'Journals',
        link: 'accounting/journals',
      },
      {
        label: 'Balance Sheets',
        link: 'accounting/balance-sheets',
      },
      {
        label: 'Accounts',
        link: 'accounting/accounts',
      },
      {
        label: 'Profit And Loss',
        link: 'accounting/profit-loss',
      },
    ],
  },
  {
    label: 'Marketing',
    icon: 'mail',
    subItems: [{ label: 'Email', link: 'marketing/email' }],
  },
  {
    label: 'HRM',
    icon: 'users',
    subItems: [
      { label: 'Employee', link: 'hrm/employees' },
      { label: 'Timesheet', link: 'hrm/employee-data' },
      { label: 'Report', link: 'hrm/report' },
      { label: 'Display', link: 'hrm/display' },
      { label: 'Settings', link: 'hrm/settings' },
    ],
  },
  {
    label: 'Settings',
    icon: 'settings',
    subItems: [
      {
        label: 'Manage Users',
        link: 'admin/users',
      },
      {
        label: 'Apps',
        link: 'admin/apps',
      },
      {
        label: 'General Settings',
        link: 'admin/general-settings',
      },
      {
        label: 'Company Settings',
        link: 'admin/company-settings',
      },
      {
        label: 'Subscription',
        link: 'admin/manage-subscription',
      },
      {
        label: 'Template',
        link: 'admin/templates',
      },
    ],
  },
];
