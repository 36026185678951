import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';

import { ProfileHttpService } from '@services';
import { environment } from '@env/environment';
import { errorAlert, successAlert } from '@utils/alert';
const base_url = environment.BASE_URL;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private http: ProfileHttpService
  ) {}

  ngOnInit(): void {
    this.fetchNotes();
    this.fetchUserDetail();
  }

  notes = [];
  userDetail = {};
  addNewNoteInput = '';

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('easerp_token');

    if (!localStorage.getItem('easerp_token')) {
      this.router.navigate(['/auth/login']);
    }

    // Remove the local storage from Products page:
    localStorage.removeItem('currentPage');
    localStorage.removeItem('productSearchQuery');
    localStorage.removeItem('productBarcodeSearchQuery');
  }

  fetchNotes() {
    this.http
      .fetchNotes()
      .pipe(
        map((response: any) => {
          this.notes = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  addNewNote(e) {
    if (e.key === 'Enter') {
      const note = {
        notes: e.target.value
      };
      //Clear input field before submit:
      this.addNewNoteInput = '';

      this.http
        .addNote(note)
        .pipe(
          map((response: any) => {
            successAlert('Note added successfully!!', response.message);
            this.fetchNotes();
          }),
          catchError((err) => {
            errorAlert(err);
            console.log(err);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  deleteNote(id) {
    this.http
      .deleteNote(id)
      .pipe(
        map((response: any) => {
          successAlert('Note deleted successfully!!', response.message);
          this.fetchNotes();
        }),
        catchError((err) => {
          errorAlert(err);
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  fetchUserDetail() {
    this.http
      .fetchUserDetail()
      .pipe(
        map((response: any) => {
          this.userDetail = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getImageUrl(url) {
    return `${base_url}${url}`;
  }
}
