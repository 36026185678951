import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '../app/utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class EmployeeDataHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/employee-data`, query);
    return this.httpClient.get(url);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/employee-data/${id}`);
  }

  public fetchReport(): Observable<any> {
    return this.httpClient.get(`${base_url}api/employee-data/report`);
  }

  public addTimesheet(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/employee-data`, data);
  }

  public updateTimesheet(id, data): Observable<any> {
    return this.httpClient.put(`${base_url}api/employee-data/${id}`, data);
  }

  public fetchTimesheet(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/employee-data/${id}`);
  }
}
