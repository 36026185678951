import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-callback',
  templateUrl: './select-callback.component.html',
  styleUrls: ['./select-callback.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectCallbackComponent),
    },
  ],
})
export class SelectCallbackComponent implements OnInit {
  @Input() items = [];
  @Input() formControlName = '';
  @Input() isValid = false;
  @Input() clientError = '';
  @Input() serverError = '';
  @Input() label = '';
  @Input() parentForm: FormGroup;
  @Input() triggerChange: (args: any) => void;

  constructor() {}

  ngOnInit(): void {}

  onChange: any = () => {};
  onTouch: any = () => {};
  val = ''; // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
