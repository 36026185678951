import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  Injectable,
} from '@angular/core';

import { EMPTY, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';

import {
  FormGroup,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';

import {
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

import { PaymentHttpService, AppService } from '@services';

import { getFormattedDate } from '@utils/date';

@Injectable()
export class CustomDateAdapter {
  fromModel(value: string): NgbDateStruct {
    if (!value) return null;
    let parts = value.split('-');
    return { year: +parts[0], month: +parts[1], day: +parts[2] };
  }

  toModel(date: NgbDateStruct): string {
    // from internal model -> your mode
    return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : null;
  }
}

@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value) return null;
    let parts = value.split('-');
    return {
      year: +parts[0],
      month: +parts[1],
      day: +parts[2],
    } as NgbDateStruct;
  }
  format(date: NgbDateStruct): string {
    return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : null;
  }
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PaymentComponent),
    },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class PaymentComponent implements OnInit {
  @Input() isModal = false;
  @Output() handleSubmit = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter();

  paymentMethods = [];
  partialPayments = [];
  data = {
    id: '',
    total: '',
    due: '',
    dueDate: '',
    invoiceDate: '',
    paid: '',
  };

  PaymentForm: FormGroup;
  subscription: Subscription;

  constructor(
    private appService: AppService,
    private http: PaymentHttpService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.subscription = this.appService.formChangeEvent().subscribe((res) => {
      if (res && res.id) {
        const total = Number(res.total);
        const due = Number(res.due);
        const paid = (total - due).toFixed(2);
        this.data = { ...res, paid, total, due };
        this.initData();
      }
    });

    this.fetchPaymentMethods();
    this.buildForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initData() {
    this.fetchPartialPayment();
  }

  fetchPaymentMethods() {
    this.http
      .fetchPaymentMethods()
      .pipe(
        map((response: any) => {
          this.paymentMethods = response;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  get payments(): FormArray {
    return this.PaymentForm.get('payments') as FormArray;
  }

  fetchPartialPayment() {
    this.http
      .fetchPartialPayment(this.data.id)
      .pipe(
        map((response: any) => {
          this.PaymentForm = this.fb.group({
            payments: this.fb.array([]),
          });

          let paid = 0;
          response.forEach((res) => {
            paid += Number(res.paid_amount);
            this.payments.push(
              this.fb.group({
                  invoice_payment_id:res.invoice_payment_id,
                  created_by:res.created_by,
                  created_date:res.created_date .split(' ')[0],
                  modified_by: res.modified_by,
                  modified_date: res.modified_date,
                  invoice_id: res.invoice_id,
                  payment_date: res.payment_date .split(' ')[0],
                  paid_amount: res.paid_amount,
                  balance_amount: res.balance_amount,
                  payment_method: res.payment_method,
                  bank_reference:res.bank_reference  || '',
                  days_late: res.days_late,
                  bank_account: res.bank_account || '' ,
                  credit_note_id: res.credit_note_id == null ? '':res.credit_note_id ,
                  invoice_payment_code: res.invoice_payment_code,
                  done: true,
                  show: true,
            
              })
            );
          });

          const total = Number(this.data.total);
          const due = total - paid;

          this.data = {
            ...this.data,
            due: due.toFixed(2),
            paid: paid.toFixed(2),
          };
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  buildForm() {
    this.PaymentForm = this.fb.group({
      payments: this.fb.array([
        this.fb.group({
          balance_amount:[0],
          bank_account: [''],
          bank_reference: [0],
          days_late: [],
          payment_date: [getFormattedDate()],
          payment_method: ['', Validators.required],
          paid_amount: [this.data.due, Validators.required],
          done: [true],
          show: [true],
        }),
      ]),
    });
  }

  addRow() {
    if (Number(this.data.due) > 0) {
      return this.fb.group({
        bank_account: '',
        bank_reference: '',
        days_late: '',
        payment_date: getFormattedDate(),
        payment_method: '',
        paid_amount: this.data.due,
        done: true,
        show: true,
      });
    }
    return null;
  }

  onBlur(event) {
    if (event.target) {
      if (!isNaN(event.target.value)) {
        event.target.value = event.target.value;
      }
    }
  }

  onChangeAmount() {
    const values = this.PaymentForm.value;
    let due: number = Number(this.data.total);
    values.payments.forEach((payment) => {
      due -= Number(payment.paid_amount);
    });
    this.data.due = due.toFixed(2);
  }

  removePaymentRow(i) {
    this.payments.removeAt(i);
    this.onChangeAmount();
  }

  addPaymentRow() {
    const row = this.addRow();
    if (row) {
      this.data.due = '0';
      this.payments.push(row);
    }
  }

  onSubmit() {
    if (this.isModal) {
      if (this.PaymentForm.valid) {
        this.handleSubmit.emit({
          id: this.data.id,
          body: this.PaymentForm.value,
        });
      }
    }
  }

  onCancel() {
    if (this.isModal) {
      this.handleCancel.emit();
    }
  }
}
