import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { parseQuery } from '@utils/query';

const base_url = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class ReceiveNotesHttpService {
  constructor(private httpClient: HttpClient) {}

  public fetchAll(query): Observable<any> {
    const url = parseQuery(`${base_url}api/receive-notes`, query);
    return this.httpClient.get(url);
  }

  public fetchStats(query: { [key: string]: any }): Observable<any> {
    const url = parseQuery(`${base_url}api/receive-note-header`, query);
    return this.httpClient.get(url);
  }

  public fetchById(id): Observable<any> {
    return this.httpClient.get(`${base_url}api/receive-notes/${id}`);
  }

  public add(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/receive-notes`, data);
  }

  public update(id, data): Observable<any> {
    return this.httpClient.patch(`${base_url}api/receive-notes/${id}`, data);
  }

  public remove(id): Observable<any> {
    return this.httpClient.delete(`${base_url}api/receive-notes/${id}`);
  }

  public email(body): Observable<any> {
    return this.httpClient.post(
      `${base_url}purchasing/receive-notes/email`,
      body
    );
  }

  public print(id): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}purchasing/receive-notes/${id}/print?token=${token}`;

    window.open(url, '_blank');
  }

  public export(): void {
    const token: string = localStorage.getItem('easerp_token');
    const url = `${base_url}purchasing/receive-note/export?token=${token}`;

    window.open(url, '_blank');
  }

  public importProductsFromReceiveNote(data): Observable<any> {
    return this.httpClient.post(`${base_url}api/v1/fetch-product`, data);
  }
}
